/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useMemo } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// import Iframe from 'react-iframe'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { Transition } from "@headlessui/react";

// import { Line } from "react-chartjs-2";

import { ModalContext } from "../content/TableProfile";

// import { dayjs } from "./Date";

import { toast } from "react-toastify";

const fetch = require('node-fetch');

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #8DC53F',
    // overflow: 'scroll',
    // maxHeight: '90%',
    maxHeight: '95%',
    maxWidth: '95%',
};


export default function PushTransactionModal() {

    const [isOpen, setIsOpen] = useState(false);

    const { swapPrice, setSwapPrice, waxPriceRam, setWaxPriceRam, pushTrx, setPushTrx } = useContext(ModalContext);

    const [activeTab, setActiveTab] = useState(0)


    const [totalResources, setTotalResources] = useState([])

    const [totalRewards, setTotalRewards] = useState([])

    // Set Bag

    const [contextMemo, setContextMemo] = useState({
        receive: "",
        quantity: 0,
        memo: "",
        typeTransfer: "wax",
        typeStake: "cpu",
        type: "stake",
        bags: [],
        land: "",
        rewards: ""
    });

    const [listAll, setListAll] = useState([])

    const [rewardsTlm, setRewardsTlm] = useState({
        tlm_rewards: 2.5
    })

    const [balance, setBalance] = useState({
        tlm: 0,
        wax: 0
    })

    const handleChange = (event) => {

        const { name, value } = event.target;
        setRewardsTlm((prevTime) => {
            return {
                ...prevTime,
                [name]: value
            };
        });
    };

    const handleChangeType = (prop) => (event) => {
        setContextMemo({ ...contextMemo, [prop]: event.target.value });
    };

    const onValueChange = (event) => {
        const { name, value } = event.target;
        setContextMemo((prevContext) => {
            return {
                ...prevContext,
                [name]: value,
            };
        });
    };

    const setMaxQuantity = (value) => {
        setContextMemo((prevContext) => {
            return {
                ...prevContext,
                "quantity": value,
            };
        });
    }

    const TlmToWax = useMemo(() => {
        return (((contextMemo.quantity * swapPrice.tlm.minReceived) * ((100 - 0.30520) / 100))).toFixed(8)
    }, [contextMemo.quantity, swapPrice.tlm.minReceived])

    const GetAssetsAll = async (account) => {
        try {
            let url = `https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=${account}&limit=1000`
            const res = await fetch(url);
            const res_json = await res.json();
            let all_transferable_nft = []
            for (let index = 0; index < res_json.data.length; index++) {
                if (res_json.data[index].is_transferable) {
                    all_transferable_nft.push(res_json.data[index])
                }
            }
            setListAll(all_transferable_nft)

        }
        catch (e) {
            console.log(e.message);
        }
    }

    const GetBalanceAll = async (account) => {
        fetch("https://wax.pink.gg/v1/chain/get_currency_balance", {
            "headers": {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "text/plain;charset=UTF-8",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "sec-gpc": "1"
            },
            "referrer": "https://wax.alcor.exchange/",
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": JSON.stringify({
                "code": "eosio.token",
                "account": account,
                "symbol": null
            }),
            "method": "POST"
        }).then(res => res.json())
            .then(json => {
                setBalance((prevContext) => {
                    return {
                        ...prevContext,
                        "wax": json.length > 0 ? json[0] : "0.00000000 WAX",
                    };
                });
            })
            .catch(err => console.error('error:' + err));

        fetch("https://wax.pink.gg/v1/chain/get_currency_balance", {
            "headers": {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "text/plain;charset=UTF-8",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "sec-gpc": "1"
            },
            "referrer": "https://wax.alcor.exchange/",
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": JSON.stringify({
                "code": "alien.worlds",
                "account": account,
                "symbol": null
            }),
            "method": "POST"
        }).then(res => res.json())
            .then(json => {
                setBalance((prevContext) => {
                    return {
                        ...prevContext,
                        "tlm": json.length > 0 ? json[0] : "0.0000 TLM",
                    };
                });
            })
            .catch(err => console.error('error:' + err));;
    }

    const getTotalRewards = async (account) => {
        await fetch("https://wax.pink.gg/v1/chain/get_table_rows", {
            "headers": {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "text/plain;charset=UTF-8",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "sec-gpc": "1"
            },
            "referrer": "https://wax.alcor.exchange/",
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": JSON.stringify({ "json": true, "code": "m.federation", "scope": "m.federation", "table": "minerclaim", "lower_bound": account, "upper_bound": account, "index_position": 1, "key_type": "", "limit": 10, "reverse": false, "show_payer": false }),
            "method": "POST",
            "mode": "cors"
        }).then(res => res.json())
            .then(json => {
                if (json.rows[0]) {
                    setTotalRewards(json);
                } else {
                    setTotalRewards({
                        "rows": [
                            {
                                "miner": "xxxxx.x.wam",
                                "amount": "0.0000 TLM",
                                "timestamp": "1970-01-01T00:00:00"
                            }
                        ],
                        "more": false,
                        "next_key": ""
                    })
                }

            })
            .catch(err => console.error('error:' + err));
    }

    const getTotalResources = async (account) => {
        await fetch("https://wax.pink.gg/v1/chain/get_account", {
            "headers": {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "text/plain;charset=UTF-8",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "sec-gpc": "1"
            },
            "referrer": "https://wax.alcor.exchange/",
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": JSON.stringify({ "account_name": account }),
            "method": "POST",
            "mode": "cors"
        }).then(res => res.json())
            .then(json => {
                setTotalResources(json);
            })
            .catch(err => console.error('error:' + err));
    }

    // const GetHistory24Hr = async () => {
    //     await fetch("https://wax.alcor.exchange/api/pools/0/charts?period=24H")
    //         .then((res) => res.json())
    //         .then((json) => {
    //             console.log(json);
    //             let labels = [];
    //             let dataTLM = [];
    //             json.forEach((element) => {
    //                 labels.push(dayjs.utc(element._id).local().format("HH:mm"));
    //                 dataTLM.push(element.price);
    //             });
    //             setData24Hr({
    //                 labels: labels,
    //                 datasets: [
    //                     {
    //                         label: "LTM / WAX ",
    //                         data: dataTLM,
    //                         fill: true,
    //                         backgroundColor: "rgba(141, 197, 63,0.2)",
    //                         borderColor: "rgba(141, 197, 63,1)"
    //                     }
    //                 ]
    //             });
    //         })
    //         .catch((err) => console.error("error:" + err));
    // };

    const getPriceSwap = async () => {
        await fetch('https://alcor.exchange/api/v2/swapRouter/getRoute?' + new URLSearchParams({
            trade_type: 'EXACT_INPUT',
            receiver: "alienscripts",
            slippage: "0.30",
            maxHops: 2,
            amount: 1,
            input: "tlm-alien.worlds",
            output: "wax-eosio.token"
        })).then(response => response.json())
            .then((result) => {
                setSwapPrice((prevKey) => {
                    return {
                        ...prevKey,
                        "tlm": result,
                    };
                });
            })
            .catch(error => console.log('error', error));

        await fetch('https://alcor.exchange/api/v2/swapRouter/getRoute?' + new URLSearchParams({
            trade_type: 'EXACT_INPUT',
            receiver: "alienscripts",
            slippage: "0.30",
            maxHops: 2,
            amount: 1,
            input: "wax-eosio.token",
            output: "tlm-alien.worlds"
        })).then(response => response.json())
            .then((result) => {
                setSwapPrice((prevKey) => {
                    return {
                        ...prevKey,
                        "wax": result,
                    };
                });
            })
            .catch(error => console.log('error', error));
        // await fetch("https://wax.pink.gg/v1/chain/get_table_rows", {
        //     "headers": {
        //         "accept": "*/*",
        //         "accept-language": "en-US,en;q=0.9",
        //         "content-type": "text/plain;charset=UTF-8",
        //         "sec-fetch-dest": "empty",
        //         "sec-fetch-mode": "cors",
        //         "sec-fetch-site": "cross-site",
        //         "sec-gpc": "1"
        //     },
        //     "referrer": "https://wax.alcor.exchange/",
        //     "referrerPolicy": "strict-origin-when-cross-origin",
        //     "body": JSON.stringify({ "json": true, "code": "alcorammswap", "scope": "alcorammswap", "table": "pairs", "table_key": "", "lower_bound": 0, "upper_bound": 0, "index_position": 1, "key_type": "", "limit": 1, "reverse": false, "show_payer": false }),
        //     "method": "POST",
        //     "mode": "cors"
        // }).then(res => res.json())
        //     .then(json => {
        //         setSwapPrice({
        //             tlm: (
        //                 parseFloat(json.rows[0].pool1.quantity) /
        //                 parseFloat(json.rows[0].pool2.quantity)
        //             ).toFixed(8),
        //             wax: (
        //                 parseFloat(json.rows[0].pool2.quantity) /
        //                 parseFloat(json.rows[0].pool1.quantity)
        //             ).toFixed(8),
        //         });
        //     })
        //     .catch(err => console.error('error:' + err));
    }

    const getPriceRam = async () => {
        await fetch("https://wax.pink.gg/v1/chain/get_table_rows", {
            "headers": {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "text/plain;charset=UTF-8",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "sec-gpc": "1"
            },
            "referrer": "https://wax.alcor.exchange/",
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": JSON.stringify({ "json": true, "code": "eosio", "scope": "eosio", "table": "rammarket", "lower_bound": "", "upper_bound": "", "index_position": 1, "key_type": "", "limit": 10, "reverse": false, "show_payer": false }),
            "method": "POST",
            "mode": "cors"
        }).then(res => res.json())
            .then(json => {
                setWaxPriceRam({
                    bytes: (
                        parseFloat(json.rows[0].quote.balance) / parseFloat(json.rows[0].base.balance)
                    ).toFixed(8),
                });
            })
            .catch(err => console.error('error:' + err));
    }

    const getRewardsSetup = async (account) => {
        await fetch(`/rewards_tlm/${account}`, {
            method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
        }).then(res => res.json())
            .then(json => {
                if (json.rewards_tlm) {
                    setRewardsTlm((prevTime) => {
                        return {
                            ...prevTime,
                            tlm_rewards: json.rewards_tlm,
                        };
                    });
                }
            })
            .catch(err => console.error(err.message));
    }

    const PushSetTools = async () => {
        let nfts = []
        const seatplan = document.getElementById('show-nfts');
        const select = seatplan.querySelectorAll('input:checked');
        select.forEach((NFTs_Select) => { nfts.push(NFTs_Select.id); });
        if (nfts.length === 0) return;
        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        account: "m.federation",
                        name: "setbag",
                        authorization: [
                            {
                                actor: pushTrx.account,
                                permission: "active",
                            },
                        ],
                        data: {
                            account: pushTrx.account,
                            items: nfts.slice(0, 3),
                        },
                    },
                ]
            })
        };


        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)

                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushRefund = async () => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        "account": "eosio",
                        "name": "refund",
                        "authorization": [
                            {
                                "actor": pushTrx.account,
                                "permission": "active"
                            }
                        ],
                        "data": {
                            "owner": pushTrx.account
                        }
                    }
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushSetStake = async () => {
        const actions = []
        if (contextMemo.type === "stake") {
            switch (contextMemo.typeStake) {
                case "cpu":
                    actions.push(
                        {
                            account: 'eosio',
                            name: "delegatebw",
                            authorization: [
                                {
                                    "actor": pushTrx.account,
                                    "permission": "active"
                                },
                            ],
                            data: {
                                from: pushTrx.account,
                                receiver: pushTrx.account,
                                stake_net_quantity: "0.00000000 WAX",
                                stake_cpu_quantity: `${parseFloat(contextMemo.quantity).toFixed(8)} WAX`,
                                transfer: false
                            },
                        },
                    )
                    break;
                case "net":
                    actions.push(
                        {
                            account: 'eosio',
                            name: "delegatebw",
                            authorization: [
                                {
                                    "actor": pushTrx.account,
                                    "permission": "active"
                                },
                            ],
                            data: {
                                from: pushTrx.account,
                                receiver: pushTrx.account,
                                stake_net_quantity: `${parseFloat(contextMemo.quantity).toFixed(8)} WAX`,
                                stake_cpu_quantity: "0.00000000 WAX",
                                transfer: false
                            },
                        },
                    )
                    break;
                case "ram":
                    actions.push(
                        {
                            account: 'eosio',
                            name: "buyram",
                            authorization: [
                                {
                                    "actor": pushTrx.account,
                                    "permission": "active"
                                },
                            ],
                            data: {
                                "payer": pushTrx.account,
                                "quant": `${parseFloat(contextMemo.quantity).toFixed(8)} WAX`,
                                "receiver": pushTrx.account,
                                transfer: false
                            },
                        },
                    )
                    break;

                default:
                    break;
            }
        } else {
            switch (contextMemo.typeStake) {
                case "cpu":
                    actions.push(
                        {
                            account: 'eosio',
                            name: "undelegatebw",
                            authorization: [
                                {
                                    "actor": pushTrx.account,
                                    "permission": "active"
                                },
                            ],
                            data: {
                                from: pushTrx.account,
                                receiver: pushTrx.account,
                                unstake_net_quantity: "0.00000000 WAX",
                                unstake_cpu_quantity: `${parseFloat(contextMemo.quantity).toFixed(8)} WAX`,
                                transfer: false
                            },
                        },
                    )
                    break;
                case "net":
                    actions.push(
                        {
                            account: 'eosio',
                            name: "undelegatebw",
                            authorization: [
                                {
                                    "actor": pushTrx.account,
                                    "permission": "active"
                                },
                            ],
                            data: {
                                from: pushTrx.account,
                                receiver: pushTrx.account,
                                unstake_net_quantity: `${parseFloat(contextMemo.quantity).toFixed(8)} WAX`,
                                unstake_cpu_quantity: "0.00000000 WAX",
                                transfer: false
                            },
                        },
                    )
                    break;
                case "ram":
                    actions.push(
                        {
                            account: 'eosio',
                            name: "sellram",
                            authorization: [
                                {
                                    "actor": pushTrx.account,
                                    "permission": "active"
                                },
                            ],
                            data: {
                                account: pushTrx.account,
                                bytes: parseInt(contextMemo.quantity / waxPriceRam.bytes),
                                transfer: false
                            },
                        },
                    )
                    break;

                default:
                    break;
            }
        }


        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: actions
            })
        };


        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushSetLand = async () => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        account: "m.federation",
                        name: "setland",
                        authorization: [
                            {
                                actor: pushTrx.account,
                                permission: "active",
                            },
                        ],
                        data: {
                            account: pushTrx.account,
                            land_id: contextMemo.land,
                        },
                    },
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushSetLandAll = async () => {

        await fetch("/account", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            }
        })
            .then(res => res.json())
            .then(json => {
                // console.log(json);
                json.account.forEach((accountWax) => {
                    const options = {
                        method: 'POST',
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('token'),
                            "Content-type": "application/json",
                            "Accept": "application/json",
                        },
                        body: JSON.stringify({
                            account: accountWax,
                            actions: [
                                {
                                    account: "m.federation",
                                    name: "setland",
                                    authorization: [
                                        {
                                            actor: accountWax,
                                            permission: "active",
                                        },
                                    ],
                                    data: {
                                        account: accountWax,
                                        land_id: contextMemo.land,
                                    },
                                },
                            ]
                        })
                    };

                    const SendPushTransaction = new Promise(async (resolve, rejected) => {
                        await fetch("/push_transaction", options)
                            .then(res => res.json())
                            .then(json => {
                                console.log(json);
                                if (json.transaction_id) {
                                    resolve(json)
                                } else {
                                    // setLogin(false)
                                    rejected(json)
                                }
                            })
                            .catch(err => rejected(err));
                    });

                    toast.promise(
                        SendPushTransaction,
                        {
                            pending: 'Promise is pending',
                            success: {
                                render({ data }) {
                                    return `${accountWax} Succeed : ${data.transaction_id.slice(0, 10)}`
                                },
                            },
                            error: {
                                render({ data }) {
                                    if (data.GetSingError === 401) {
                                        return `Failed : Token expired please new login`
                                    } else {
                                        // When the promise reject, data will contains the error
                                        return `${accountWax} Failed : ${data.DetailsError[0].what}`
                                    }
                                }
                            }
                        }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
                    )
                })

            })
            .catch(err => console.log(err));

    }

    const PushTransferSwap = async () => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        account: "alien.worlds",
                        name: "transfer",
                        authorization: [
                            {
                                "actor": pushTrx.account,
                                "permission": "active"
                            }
                        ],
                        data: {
                            from: pushTrx.account,
                            to: "alcorammswap",
                            quantity: `${parseFloat(contextMemo.quantity).toFixed(4)} TLM`,
                            memo: `${(((contextMemo.quantity * swapPrice.tlm.minReceived) * ((100 - 0.5) / 100))).toFixed(8)} WAX@eosio.token`
                        },
                    },
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) { return `Succeed : ${data.transaction_id.slice(0, 10)}` },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushTransfer = async () => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        account: contextMemo.typeTransfer === "wax" ? "eosio.token" : "alien.worlds",
                        name: "transfer",
                        authorization: [
                            {
                                actor: pushTrx.account,
                                permission: "active"
                            }
                        ],
                        data: {
                            from: pushTrx.account,
                            to: contextMemo.receive,
                            quantity: contextMemo.typeTransfer === "wax" ? `${parseFloat(contextMemo.quantity).toFixed(8)} WAX` : `${parseFloat(contextMemo.quantity).toFixed(4)} TLM`,
                            memo: contextMemo.memo
                        },
                    },
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushRewardsSetup = async (all = false) => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                setAll: all,
                account: pushTrx.account,
                actions: {
                    rewards_tlm: rewardsTlm.tlm_rewards,
                },
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/rewards_tlm", options)
                .then(res => res.json())
                .then(json => resolve(json))
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) { return `Succeed Setup` },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed Setup`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushRewards = async () => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        "account": "m.federation",
                        "name": "claimmines",
                        "authorization": [
                            {
                                "permission": "active",
                                "actor": pushTrx.account
                            }
                        ],
                        "data": {
                            "receiver": pushTrx.account
                        }
                    }
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }


    const PushTransferNTFs = async () => {
        let nfts = []
        const seatplan = document.getElementById('show-nfts');
        const select = seatplan.querySelectorAll('input:checked');
        select.forEach((NFTs_Select) => { nfts.push(NFTs_Select.id); });
        if (nfts.length === 0) return;
        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        account: "atomicassets",
                        name: "transfer",
                        authorization: [
                            {
                                actor: pushTrx.account,
                                permission: "active",
                            },
                        ],
                        data: {
                            from: pushTrx.account,
                            to: contextMemo.receive,
                            asset_ids: nfts,
                            memo: "",
                        },
                    },
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushAllTransferNTFs = async () => {
        let nfts = []
        listAll.forEach((tools) => { console.log(tools.asset_id); nfts.push(tools.asset_id) })
        if (nfts.length === 0) return;
        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: pushTrx.account,
                actions: [
                    {
                        account: "atomicassets",
                        name: "transfer",
                        authorization: [
                            {
                                actor: pushTrx.account,
                                permission: "active",
                            },
                        ],
                        data: {
                            from: pushTrx.account,
                            to: contextMemo.receive,
                            asset_ids: nfts,
                            memo: "",
                        },
                    },
                ]
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/push_transaction", options)
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.transaction_id) {
                        resolve(json)
                    } else {
                        // setLogin(false)
                        rejected(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.transaction_id.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        if (data.GetSingError === 401) {
                            return `Failed : Token expired please new login`
                        } else {
                            // When the promise reject, data will contains the error
                            return `Failed : ${data.DetailsError[0].what}`
                        }
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    let intervalID;
    useEffect(() => {
        if (pushTrx.show) {
            // GetHistory24Hr()
            GetBalanceAll(pushTrx.account)
            GetAssetsAll(pushTrx.account)
            getTotalResources(pushTrx.account)
            getTotalRewards(pushTrx.account)
            getPriceRam()
            getRewardsSetup(pushTrx.account)
            getPriceSwap()
            // intervalID = setInterval(async () => { await getPriceSwap(); }, 30000);
        }
        return () => clearInterval(intervalID);
    }, [pushTrx])

    useEffect(() => {
        if (activeTab === 0 && pushTrx.show) {
            GetBalanceAll(pushTrx.account)
        } else if (activeTab === 1 && pushTrx.show) {
            GetBalanceAll(pushTrx.account)
        }
    }, [activeTab])

    return (
        <>
            <Modal
                open={pushTrx.show}
                onClose={() => setPushTrx({ ...!pushTrx, show: !pushTrx.show })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style} className={"text-gray-darker dark:text-gray-light bg-gray-lighter dark:bg-gray-darker rounded-xl font-semibold p-3 w-full md:w-auto overflow-auto"}>
                    <div>
                        <nav className="bg-transparent">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex items-center justify-center h-16">
                                    <div className="flex items-center">
                                        <div className="hidden whitespace-nowrap md:block">
                                            <div className="flex flex-row uppercase">
                                                <a className={`cursor-pointer flex w-full px-3 py-2 border-b-8 text-md font-medium ${activeTab === 0 ? "border-green text-green" : null}`} onClick={() => setActiveTab(0)} > SWAP TLM / WAX </a>
                                                <a className={`cursor-pointer flex w-full px-3 py-2 border-b-8 text-md font-medium ${activeTab === 1 ? "border-green text-green" : null}`} onClick={() => setActiveTab(1)} > TRANSFER </a>
                                                <a className={`cursor-pointer flex w-full px-3 py-2 border-b-8 text-md font-medium ${activeTab === 2 ? "border-green text-green" : null}`} onClick={() => setActiveTab(2)} > RESOURCES </a>
                                                <a className={`cursor-pointer flex w-full px-3 py-2 border-b-8 text-md font-medium ${activeTab === 3 ? "border-green text-green" : null}`} onClick={() => setActiveTab(3)} > REWARDS CLAIMS [TLM] </a>
                                                <a className={`cursor-pointer flex w-full px-3 py-2 border-b-8 text-md font-medium ${activeTab === 4 ? "border-green text-green" : null}`} onClick={() => setActiveTab(4)} > SET TOOLS </a>
                                                <a className={`cursor-pointer flex w-full px-3 py-2 border-b-8 text-md font-medium ${activeTab === 5 ? "border-green text-green" : null}`} onClick={() => setActiveTab(5)} > NFTs </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex md:hidden text-lg cursor-pointer border-2 border-green rounded-lg px-9">
                                        <span className="inline-flex items-center justify-center" onClick={() => setIsOpen(!isOpen)}>{activeTab === 0 ? "SWAP TLM / WAX" : activeTab === 1 ? "TRANSFER WAX" : activeTab === 2 ? "RESOURCES" : activeTab === 3 ? "REWARDS CLAIMS [TLM]" : activeTab === 4 ? "SET TOOLS" : "NFTs"}</span>
                                    </div>
                                </div>
                            </div>

                            <Transition
                                show={isOpen}
                                enter="transition ease-out duration-100 transform"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-75 transform"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                {(ref) => (
                                    <div className="md:hidden" id="mobile-menu">
                                        <div ref={ref} className="px-2 whitespace-nowrap pt-2 pb-3 space-y-1 sm:px-3" onClick={() => setIsOpen(!isOpen)}>
                                            <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 0 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(0)} > SWAP TLM / WAX </a>
                                            <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 1 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(1)} > TRANSFER </a>
                                            <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 2 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(2)} > RESOURCES </a>
                                            <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 3 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(3)} > REWARDS CLAIMS [TLM] </a>
                                            <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 4 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(4)} > SET TOOLS </a>
                                            <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 5 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(5)} > NFTs </a>
                                        </div>
                                    </div>
                                )}
                            </Transition>
                        </nav>
                    </div>
                    {activeTab === 0 ?
                        <div className="relative justify-center items-center">
                            <h1 className="font-bold">Swap TLM / WAX [ {balance.tlm} ]</h1>
                            {/* <Iframe url="https://alcor.exchange/trade/tlm-alien.worlds_wax-eosio.token"
                                width="946px"
                                height="598px"
                                id=""
                                className=""
                                display="block"
                                position="relative" /> */}
                            {/* {data24Hr.length === 0 ? null : <Line data={data24Hr} options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: (context) => ("1 WAX : " + Number(context.parsed.y).toFixed(8) + " TLM"),
                                            title: (context) => ("TIME : " + context[0].label),
                                            labelTextColor: (context) => '#B5ED67'
                                        },
                                        titleAlign: "center",
                                        displayColors: false

                                    }
                                },
                                layout: {
                                    padding: 0
                                },
                                scales: {
                                    x: {

                                        display: true,
                                        title: {
                                            display: false,
                                            text: 'Time.'
                                        },
                                        ticks: {
                                            // color: '#659D17',
                                        }
                                    },
                                    y: {
                                        display: true,
                                        title: {
                                            display: false,
                                            text: 'value'
                                        },
                                        ticks: {
                                            // color: '#659D17',
                                            callback: function (value, index, values) { return Number(value).toFixed(4) + " TLM"; }
                                        }
                                    }
                                }
                            }} />} */}
                            <div className="flex flex-col sm:justify-center sm:space-x-6 sm:flex-row">
                                <div className="flex flex-nowrap items-center order-2 sm:order-none space-x-2">
                                    <img
                                        src={require("../../assets/img/wax.png").default}
                                        alt="TLM"
                                        style={{ width: "1rem", height: "1rem" }}
                                    />
                                    <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-sm"> 1 WAX = {Number.parseFloat(swapPrice.wax.minReceived).toFixed(8)} TLM</span>
                                </div>
                                <div className="flex flex-nowrap items-center order-3 sm:order-none space-x-2">
                                    <img
                                        src={require("../../assets/img/tlm.png").default}
                                        alt="WAX"
                                        style={{ width: "1rem", height: "1rem" }}
                                    />
                                    <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-sm"> 1 TLM = {Number.parseFloat(swapPrice.tlm.minReceived).toFixed(8)} WAX</span>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-3 md:flex-row mt-2">
                                <div className="flex flex-row w-full md:w-6/12">
                                    <input
                                        className="flex w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest"
                                        type="text"
                                        placeholder="Amount"
                                        name="quantity"
                                        value={contextMemo.quantity}
                                        onChange={({ target: { value } }) => { if (/^\d+(\.\d{0,8})?$/.test(value)) { onValueChange({ target: { name: "quantity", value: value } }); } }}
                                    />
                                    <button className="bg-gray-light hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-gray-darkest px-4 h-8 w-24 -ml-24 rounded-lg" onClick={() => setMaxQuantity(parseFloat(balance.tlm))} >MAX</button>
                                </div>

                                <button className={`flex w-full md:w-6/12 items-center justify-center h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-gray-darkest px-4 rounded-lg`} onClick={() => PushTransferSwap()}>Swap {Number(TlmToWax).toFixed(8)} WAX</button>
                            </div>
                        </div>
                        : activeTab === 1 ?
                            <div className="relative justify-center items-center">
                                <h1 className="font-bold whitespace-nowrap">TRANSFER</h1>
                                <div className="flex flex-col space-y-2 mt-2">
                                    <input
                                        className="w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest "
                                        type="text"
                                        placeholder="Receive Wax ID"
                                        name="receive"
                                        value={contextMemo.receive}
                                        onChange={onValueChange}
                                    />
                                    <div className="flex flex-row space-x-2">
                                        <input
                                            className="w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest "
                                            type="text"
                                            placeholder={`Amount Of ${contextMemo.typeTransfer === "wax" ? "WAX (WAXP)" : "Alien Worlds (TLM)"}`}
                                            name="quantity"
                                            value={contextMemo.quantity}
                                            onChange={({ target: { value } }) => { if (/^\d+(\.\d{0,8})?$/.test(value)) { onValueChange({ target: { name: "quantity", value: value } }); } }}
                                        />
                                        <button className="bg-gray-light hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-gray-darkest px-4 h-8 w-24 -ml-0 rounded-lg" onClick={() => setMaxQuantity(parseFloat(contextMemo.typeTransfer === "wax" ? balance.wax : balance.tlm))}  >MAX</button>
                                        <select className="bg-gray-light hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-gray-darkest px-2 h-8 -ml-84 rounded-lg outline-none" name="typeTransfer" onChange={handleChangeType("typeTransfer")}  >
                                            <option className="py-1" value="wax">{balance.wax}</option>
                                            <option className="py-1" value="tlm">{balance.tlm}</option>
                                        </select>
                                    </div>

                                    <input
                                        className="w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest "
                                        type="text"
                                        placeholder="Memo"
                                        name="memo"
                                        value={contextMemo.memo}
                                        onChange={onValueChange}
                                    />
                                    <button className="w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg" onClick={() => PushTransfer()}>Transfer {contextMemo.quantity} {contextMemo.typeTransfer === "wax" ? "WAX" : "TLM"} To {contextMemo.receive}</button>
                                </div>
                            </div>
                            : activeTab === 2 ?
                                <div className="relative justify-center items-center">
                                    <h1 className="font-bold">Network Resources</h1>
                                    <div className="mt-4 mx-auto">
                                        <div className="flex flex-col w-full space-y-2 md:space-y-0 md:space-x-3 md:flex-row mt-2">
                                            <FormControl variant="standard" fullWidth={true}>
                                                <TextField
                                                    select
                                                    label={`Stake : ${contextMemo.typeStake === "cpu" ? totalResources.total_resources.cpu_weight : contextMemo.typeStake === "net" ? totalResources.total_resources.net_weight : `${Number(totalResources.total_resources.ram_bytes * waxPriceRam.bytes).toFixed(8)} WAX`}`}
                                                    name="typeStake"
                                                    value={contextMemo.typeStake}
                                                    onChange={onValueChange}
                                                    size="small"
                                                >
                                                    <MenuItem value={"cpu"}>CPU</MenuItem>
                                                    <MenuItem value={"net"}>NET</MenuItem>
                                                    <MenuItem value={"ram"}>RAM</MenuItem>
                                                </TextField>
                                            </FormControl>
                                            <FormControl variant="standard" fullWidth={true}>
                                                <TextField
                                                    select
                                                    label="Type"
                                                    name="type"
                                                    value={contextMemo.type}
                                                    onChange={onValueChange}
                                                    size="small"
                                                >
                                                    <MenuItem value={"stake"}>Stake</MenuItem>
                                                    <MenuItem value={"unstake"}>UnStake</MenuItem>
                                                </TextField>
                                            </FormControl>
                                            <FormControl variant="outlined" fullWidth={true}>
                                                <TextField
                                                    label="Quantity"
                                                    name="quantity"
                                                    type="text"
                                                    value={contextMemo.quantity}
                                                    onChange={({ target: { value } }) => { if (/^\d+(\.\d{0,8})?$/.test(value)) { onValueChange({ target: { name: "quantity", value: value } }); } }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{"WAX"}</InputAdornment>,
                                                    }}
                                                    size="small"
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="flex flex-row mt-2 space-x-3">
                                            <button className="bg-gray-lighter w-full hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-green px-4 h-8 rounded-lg" onClick={() => PushSetStake()}>Submit</button>
                                            <button className="bg-gray-lighter w-full hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-green px-4 h-8 rounded-lg" onClick={() => PushRefund()}>Refund</button>
                                        </div>

                                    </div>
                                </div>
                                : activeTab === 3 ?
                                    <div className="grid grid-cols-2 gap-2 space-x-3 relative justify-center ">
                                        <div>
                                            <h1 className="font-bold">Setup</h1>
                                            <div className="mt-4 mx-auto">
                                                <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-3 md:flex-row mt-2">
                                                    <input
                                                        className="flex w-full h-8 pl-3 pr-3 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest"
                                                        type={"number"}
                                                        placeholder="Rewards Claims"
                                                        name="tlm_rewards"
                                                        defaultValue={0}
                                                        value={Number(rewardsTlm.tlm_rewards).toFixed(4)}
                                                        onChange={handleChange}
                                                        min="1"
                                                        max="999"
                                                        step="0.5"
                                                        readOnly={false}
                                                    />
                                                    <button className={`w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg`} onClick={() => PushRewardsSetup(false)}>Save</button>
                                                    <button className={`w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg`} onClick={() => PushRewardsSetup(true)}>All</button>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <h1 className="font-bold">Claims</h1>
                                            <div className="mt-4 mx-auto">
                                                <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-3 md:flex-row mt-2">
                                                    <input
                                                        className="w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest"
                                                        type="text"
                                                        placeholder="Rewards Claims"
                                                        name="rewards"
                                                        value={totalRewards.rows[0].amount}
                                                        readOnly={true}
                                                    />
                                                    <button className={`w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg`} onClick={() => PushRewards(false)}>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div> : activeTab === 4 ?
                                        <div className="relative justify-center items-center">
                                            <h5>Set Land</h5>
                                            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-3 md:flex-row mt-2">
                                                <input
                                                    className="w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest"
                                                    type="text"
                                                    placeholder="ID Land"
                                                    name="land"
                                                    value={contextMemo.land}
                                                    onChange={onValueChange}
                                                />
                                                <button className={`w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg`} onClick={() => PushSetLand(false)}>LAND SINGLE</button>
                                                <button className={`w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg`} onClick={() => PushSetLandAll(true)}>LAND ALL</button>
                                            </div>
                                            <h5>Set Bags</h5>
                                            {listAll.length > 0 ?
                                                <section id="show-nfts" className="p-2  max-h-96 overflow-y-auto">
                                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center justify-center">
                                                        {listAll.map((item, i) => {
                                                            if (item.schema.schema_name === "tool.worlds") {
                                                                return (
                                                                    <div key={item.asset_id}>
                                                                        <div className="flex w-full items-center justify-center quiz_card_area">
                                                                            <input className="quiz_checkbox" type="checkbox" id={item.asset_id} value={item.asset_id} defaultValue={"yes"} />
                                                                            <div className="single_quiz_card flex flex-col items-center rounded-xl">
                                                                                <div className="quiz_card_content">
                                                                                    <div className="quiz_card_icon m-2">
                                                                                        <img src={`https://ipfs.io/ipfs/${item.data.img}`} style={{ height: 180 }} alt={item.data.name} />
                                                                                    </div>
                                                                                    <div className="quiz_card_title rounded-b-lg mt-2">
                                                                                        <div className="text-sm whitespace-nowrap">{item.data.name}</div>
                                                                                        <div className="text-xs">ID : {item.asset_id}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </section> : <div></div>
                                            }
                                            <button className={`w-full h-8 bg-gray-lightest hover:bg-green hover:text-gray-lightest text-gray-darkest font-bold border-2 border-gray-darkest px-4 rounded-lg mt-2`} onClick={() => PushSetTools()}>SET Mining Equipment</button>
                                        </div>
                                        :
                                        <div className="relative justify-center items-center">

                                            <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-2 md:flex-row">
                                                <input
                                                    className="flex w-full h-8 pl-3 pr-8 text-sm bg-gray-lightest placeholder-gray-dark border-2 border-gray-darkest rounded-lg focus:outline-none text-gray-darkest"
                                                    type="text"
                                                    placeholder="WAX Account Receive NFTs"
                                                    name="receive"
                                                    value={contextMemo.receive}
                                                    onChange={onValueChange}
                                                />
                                                <button className="bg-gray-light hover:bg-orange hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-gray-darkest px-4 h-8 w-full md:w-38 ml-0 md:-ml-24 rounded-lg" onClick={() => PushTransferNTFs()} >TRANSFER SELECT</button>
                                                <button className="bg-gray-light hover:bg-orange hover:text-gray-lightest text-gray-darkest font-bold text-sm border-2 border-gray-darkest px-4 h-8 w-full md:w-38 ml-0 md:-ml-24 rounded-lg" onClick={() => PushAllTransferNTFs()} >TRANSFER ALL</button>

                                            </div>

                                            {listAll.length > 0 ?
                                                <section id="show-nfts" className="p-2  max-h-96 overflow-y-auto">
                                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center justify-center">
                                                        {listAll.map((item, i) => {
                                                            if (item.is_transferable) {
                                                                return (
                                                                    <div key={item.asset_id}>
                                                                        <div className="flex w-full items-center justify-center quiz_card_area">
                                                                            <input className="quiz_checkbox" type="checkbox" id={item.asset_id} value={item.asset_id} defaultValue={"yes"} />
                                                                            <div className="single_quiz_card flex flex-col items-center rounded-xl">
                                                                                <div className="quiz_card_content">
                                                                                    <div className="quiz_card_icon m-2">
                                                                                        <img src={`https://ipfs.io/ipfs/${item.data.img}`} style={{ height: 180 }} alt={item.data.name} />
                                                                                    </div>
                                                                                    <div className="quiz_card_title rounded-b-lg mt-2">
                                                                                        <div className="text-sm whitespace-nowrap">{item.data.name}</div>
                                                                                        <div className="text-xs">ID : {item.asset_id}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }

                                                        })}
                                                    </div>
                                                </section> : <div></div>
                                            }
                                        </div>
                    }
                </Box>
            </Modal>
        </>
    );
}

