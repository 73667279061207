/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { InfoAddAccount } from "../../App";
import { Box, Modal, Backdrop, TextField, Button, FormControlLabel, Checkbox,Stepper,Step,StepLabel,StepContent,Paper,Typography } from '@mui/material';
import { UilFolderDownload } from '@iconscout/react-unicons'
import xlsx from "xlsx";

const style = {
  position: 'absolute',
  // overflow: 'scroll',
  maxHeight: '95%',
  maxWidth: '95%',
  display: 'block',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #8DC53F',
};

const PushAccount = () => {
  const { popAddAccount, setPopAddAccount, } = useContext(InfoAddAccount);
  const [multipleAcc, setMultipleAcc] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [listAll, setListAll] = useState([])
  const [listCreation, setCharacter_Creation] = useState({
    creation: false,
    stake_cpu: 0,
    stake_net: 0,
    stake_ram: 0,
    set_land_id: ""
  });


  const handleChangeAutoCharacter_Creation = (event) => {
    const { name, value } = event.target;
    setCharacter_Creation((prevKey) => {
      return {
        ...prevKey,
        [name]: value,
        "creation": event.target.checked
      };
    });
  };


  const readUploadFile = (e) => {
    const typeFile = e.target.value
    e.preventDefault();
    const reader = new FileReader();

    if (typeFile.includes(".txt")) {
      reader.onload = function (e) {
        let text = e.target.result;
        const txtArr = text.split('\n').map(entry => {
          const obj = {}
          entry.split('\n').forEach(keyValue => {
            const split = keyValue.split("//");
            obj["account"] = split[0];
            obj["email"] = split[1];
            obj["password"] = split[2];
            obj["secret"] = split[3];
          })
          const jsonData = JSON.stringify(obj).replace(/\s/g, "")
          return JSON.parse(jsonData)
        })
        console.log('txtArray => ', txtArr)
        setMultipleAcc(txtArr);
      };
      reader.readAsText(e.target.files[0]);
    } else if (typeFile.includes(".xlsx")) {
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log('xlsxArray => ', json)
        setMultipleAcc(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    } else {
      reader.readAsText(e.target.files[0], "UTF-8");
      reader.onload = (e) => {
        console.log("jsonArray", JSON.parse(e.target.result));
        setMultipleAcc(JSON.parse(e.target.result));
      };
    }
  }

  const AddAccount = async (data) => {
    const options = {
      method: "POST",
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
        "Content-type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        account: data.account,
        //email: data.email,
        //password: data.password,
        secret: data.secret,
      }),
    };

    const SendPushTransaction = new Promise(async (resolve, rejected) => {
      await fetch("/accounts", options)
        .then(async (res) => {
          if (res.status === 200) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            resolve(true)
          } else {
            // setActiveStep((prevActiveStep) => prevActiveStep + 1)
            rejected(false)
          }
        })
        .catch(err => rejected(false));
    });

    toast.promise(
      SendPushTransaction,
      {
        pending: 'Promise is pending',
        success: {
          render({ data }) {
            return `ADD WAX : Succeed`
          },
        },
        error: {
          render({ data }) {
            // When the promise reject, data will contains the error
            return `ADD WAX : Failed`
          }
        }
      }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
    )

  };

  const PushSetStake = async (data) => {

    const options = {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
        "Content-type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        account: data.account,
        actions: [
          {
            account: 'eosio',
            name: "delegatebw",
            authorization: [
              {
                "actor": data.account,
                "permission": "active"
              },
            ],
            data: {
              from: data.account,
              receiver: data.account,
              stake_net_quantity: `${parseFloat(data.resources_NET).toFixed(8)} WAX`,
              stake_cpu_quantity: `${parseFloat(data.resources_CPU).toFixed(8)} WAX`,
              transfer: false
            },
          },
          {
            account: 'eosio',
            name: "buyram",
            authorization: [
              {
                "actor": data.account,
                "permission": "active"
              },
            ],
            data: {
              "payer": data.account,
              "quant": `${parseFloat(data.resources_RAM).toFixed(8)} WAX`,
              "receiver": data.account,
              transfer: false
            },
          },
        ]
      })
    };


    const SendPushTransaction = new Promise(async (resolve, rejected) => {
      await fetch("/push_transaction", options)
        .then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.transaction_id) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            resolve(json)
          } else {
            rejected(json)
          }
        })
        .catch(err => rejected(err));
    });

    toast.promise(
      SendPushTransaction,
      {
        pending: 'Promise is pending',
        success: {
          render({ data }) {
            return `Succeed : ${data.transaction_id.slice(0, 10)}`
          },
        },
        error: {
          render({ data }) {
            // When the promise reject, data will contains the error
            return `Failed : ${data.DetailsError[0].what}`
          }
        }
      }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
    )
  }

  const PushNewAccount = async (data) => {



    const options = {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
        "Content-type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        account: data.account,
        actions: [
          {
            "account": "federation",
            "name": "agreeterms",
            "authorization": [
              {
                "actor": data.account,
                "permission": "active"
              }
            ],
            "data": {
              "account": data.account,
              "terms_id": 1,
              "terms_hash": "e2e07b7d7ece0d5f95d0144b5886ff74272c9873d7dbbc79bc56f047098e43ad"
            }
          },
          {
            "account": "federation",
            "name": "setavatar",
            "authorization": [
              {
                "actor": data.account,
                "permission": "active"
              }
            ],
            "data": {
              "account": data.account,
              "avatar_id": 1
            }
          },
          {
            "account": "federation",
            "name": "settag",
            "authorization": [
              {
                "actor": data.account,
                "permission": "active"
              }
            ],
            "data": {
              "account": data.account,
              "tag": newAccount.newName
            }
          },
          {
            "account": "m.federation",
            "name": "setland",
            "authorization": [
              {
                "actor": data.account,
                "permission": "active"
              }
            ],
            "data": {
              "account": data.account,
              "land_id": newAccount.newLand
            }
          }
        ]

      })
    };


    const SendPushTransaction = new Promise(async (resolve, rejected) => {
      await fetch("/push_transaction", options)
        .then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.transaction_id) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            resolve(json)
          } else {
            rejected(json)
          }
        })
        .catch(err => rejected(err));
    });

    toast.promise(
      SendPushTransaction,
      {
        pending: 'Promise is pending',
        success: {
          render({ data }) {
            return `Succeed : ${data.transaction_id.slice(0, 10)}`
          },
        },
        error: {
          render({ data }) {
            // When the promise reject, data will contains the error
            return `Failed : ${data.DetailsError[0].what}`
          }
        }
      }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
    )
  }

  const PushNewSetBags = async (data) => {

    console.log(data);
    let nfts = []
    const seatplan = document.getElementById('show-nfts');
    const select = seatplan.querySelectorAll('input:checked');
    select.forEach((NFTs_Select) => { nfts.push(NFTs_Select.id); });
    if (nfts.length === 0) return;
    const options = {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
        "Content-type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        account: data.account,
        actions: [
          {
            account: "m.federation",
            name: "setbag",
            authorization: [
              {
                actor: data.account,
                permission: "active",
              },
            ],
            data: {
              account: data.account,
              items: nfts.slice(0, 3),
            },
          },
        ]
      })
    };


    const SendPushTransaction = new Promise(async (resolve, rejected) => {
      await fetch("/push_transaction", options)
        .then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.transaction_id) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            resolve(json)
          } else {
            rejected(json)
          }
        })
        .catch(err => rejected(err));
    });

    toast.promise(
      SendPushTransaction,
      {
        pending: 'Promise is pending',
        success: {
          render({ data }) {
            return `Succeed : ${data.transaction_id.slice(0, 10)}`
          },
        },
        error: {
          render({ data }) {
            // When the promise reject, data will contains the error
            return `Failed : ${data.DetailsError[0].what}`
          }
        }
      }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
    )
  }

  const CreateAccountMultiple = async () => {
    for await (const multiple of multipleAcc) {
      const options = {
        method: "POST",
        headers: {
          'Authorization': "Bearer " + localStorage.getItem('token'),
          "Content-type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          account: multiple.account,
          //email: multiple.email,
          //password: multiple.password,
          secret: multiple.secret,
          stake_cpu: Number(listCreation.stake_cpu),
          stake_net: Number(listCreation.stake_net),
          stake_ram: Number(listCreation.stake_ram),
          set_land_id: Number(listCreation.set_land_id)
        }),
      };

      const SendPushTransaction = new Promise(async (resolve, rejected) => {
        await fetch("/create_accounts", options)
          .then(res => {
            if (res.status === 200) {
              resolve(true)
            } else {
              rejected(false)
            }
          })
          .catch(err => rejected(false));
      });

      toast.promise(
        SendPushTransaction,
        {
          pending: 'Promise is pending',
          success: {
            render({ data }) {
              return `${multiple.account.toUpperCase()} : Succeed`
            },
          },
          error: {
            render({ data }) {
              // When the promise reject, data will contains the error
              return `${multiple.account.toUpperCase()} : Failed`
            }
          }
        }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
      )
    }
  }

  const AddAccountMultiple = async () => {
    for await (const multiple of multipleAcc) {
      const options = {
        method: "POST",
        headers: {
          'Authorization': "Bearer " + localStorage.getItem('token'),
          "Content-type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          account: multiple.account,
          //email: multiple.email,
          //password: multiple.password,
          secret: multiple.secret,
        }),
      };

      const SendPushTransaction = new Promise(async (resolve, rejected) => {
        await fetch("/accounts", options)
          .then(res => {
            if (res.status === 200) {
              resolve(true)
            } else {
              rejected(false)
            }
          })
          .catch(err => rejected(false));
      });

      toast.promise(
        SendPushTransaction,
        {
          pending: 'Promise is pending',
          success: {
            render({ data }) {
              return `${multiple.account.toUpperCase()} : Succeed`
            },
          },
          error: {
            render({ data }) {
              // When the promise reject, data will contains the error
              return `${multiple.account.toUpperCase()} : Failed`
            }
          }
        }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
      )
    }
  }

  const [newAccount, setNewAccount] = useState({
    account: "",
    //email: "",
    //password: "",
    secret: "",
    resources_CPU: 10,
    resources_NET: 0.5,
    resources_RAM: 0.5,
    newName: "",
    newLand: "",
  });

  // Set Bag

  const GetTools = async (account) => {
    if (account === "") return
    try {//https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=
      let url = `https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=${account}&limit=1000&schema_name=tool.worlds`
      const res = await fetch(url);
      const res_json = await res.json();
      let all_transferable_nft = []
      for (let index = 0; index < res_json.data.length; index++) {
        if (res_json.data[index].is_transferable) {
          all_transferable_nft.push(res_json.data[index])
        }
      }
      setListAll(all_transferable_nft)

    }
    catch (e) {
      console.log(e.message);
    }
  }

  const onValueChange = (event) => {
    const { name, value } = event.target;
    setNewAccount((prevAccount) => {
      return {
        ...prevAccount,
        [name]: value,
      };
    });
  };

  const steps = [
    {
      label: 'ADD Account Wax in database',
    },
    {
      label: 'Need resources? Stake WAXP',
    },
    {
      label: 'Create an account alienworlds.io',
    },
    {
      label: 'Setup Tools',
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (activeStep === 3) {
      GetTools(newAccount.account)
    }

  }, [activeStep])


  return (<>
    <Modal
      fullWidth={true}
      open={popAddAccount}
      onClose={() => setPopAddAccount(!popAddAccount)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={style} className={"text-gray-darker dark:text-gray-light bg-gray-lighter dark:bg-gray-darker rounded-xl font-semibold  outline-none p-3 overflow-auto"}>
        <div className="relative justify-center items-center">
          <div className="flex items-center justify-center">
            <div className="flex items-center">
              <div className="block whitespace-nowrap mb-4">
                <div className="flex flex-row uppercase">
                  <a className={`cursor-pointer flex w-full px-3 border-b-8 text-md font-medium ${activeTab === 0 ? "border-green text-green" : null}`} onClick={() => setActiveTab(0)} > Single </a>
                  <a className={`cursor-pointer flex w-full px-3 border-b-8 text-md font-medium ${activeTab === 1 ? "border-green text-green" : null}`} onClick={() => setActiveTab(1)} > Multiple </a>
                </div>
              </div>
            </div>
          </div>
          {activeTab === 0 ?
            <div className={`grid grid-cols-1 w-72`}>

              <Box sx={{ maxWidth: 900 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          index === 3 ? (
                            <Typography variant="caption">Last step</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        {index === 0 ? <div className="flex flex-col space-y-2">
                          <TextField
                            type='text'
                            variant="standard"
                            label="Account Wax"
                            name="account"
                            value={newAccount.account}
                            onChange={onValueChange}
                            size="small"
                          />
                          <TextField
                            type='text'
                            variant="standard"
                            label="Private Active Key"
                            name="secret"
                            value={newAccount.secret}
                            onChange={onValueChange}
                            size="small"
                          />
                          <Box sx={{ mb: 2 }}>
                            <div>
                              <Button
                                variant="contained"
                                onClick={() => AddAccount(newAccount)}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Submit
                              </Button>
                              <Button
                                // disabled={index === 0}
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Skip
                              </Button>
                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Back
                              </Button>
                            </div>
                          </Box>
                        </div> : index === 1 ? <div className="flex flex-col space-y-2">
                          <TextField
                            type="number"
                            variant="standard"
                            label="Stake CPU"
                            name="resources_CPU"
                            value={newAccount.resources_CPU}
                            onChange={onValueChange}
                            size="small"
                          />
                          <TextField
                            type="number"
                            variant="standard"
                            label="Stake NET"
                            name="resources_NET"
                            value={newAccount.resources_NET}
                            onChange={onValueChange}
                            size="small"
                          />
                          <TextField
                            type="number"
                            variant="standard"
                            label="Stake RAM"
                            name="resources_RAM"
                            value={newAccount.resources_RAM}
                            onChange={onValueChange}
                            size="small"
                          />
                          <Box sx={{ mb: 2 }}>
                            <div>
                              <Button
                                variant="contained"
                                onClick={() => PushSetStake(newAccount)}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Submit
                              </Button>
                              <Button
                                disabled={index === 0}
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Skip
                              </Button>
                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Back
                              </Button>
                            </div>
                          </Box>
                        </div>

                          : index === 2 ? <div className="flex flex-col space-y-2">

                            <TextField
                              type='text'
                              variant="standard"
                              label="Name"
                              name="newName"
                              value={newAccount.newName}
                              onChange={onValueChange}
                              size="small"
                              fullWidth
                            />
                            <TextField
                              type="number"
                              variant="standard"
                              label="Land ID"
                              name="newLand"
                              value={newAccount.newLand}
                              onChange={onValueChange}
                              size="small"
                              fullWidth
                            />

                            <Box sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={() => PushNewAccount(newAccount)}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Submit
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Skip
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Back
                                </Button>
                              </div>
                            </Box>
                          </div> :
                            <div>
                              <p className="text-center text-lg">Set Mining tools</p>

                              <section id="show-nfts" className="grid grid-cols-3 gap-1">
                                {listAll.map((item, i) => {
                                  if (item.schema.schema_name === "tool.worlds") {
                                    return (
                                      <div key={item.asset_id}>
                                        <div className="flex w-full items-center justify-center quiz_card_area">
                                          <input className="quiz_checkbox" type="checkbox" id={item.asset_id} value={item.asset_id} defaultValue={"yes"} />
                                          <div className="single_quiz_card flex flex-col items-center rounded-xl">
                                            <div className="quiz_card_content">
                                              <div className="quiz_card_icon m-2">
                                                <img src={`https://ipfs.io/ipfs/${item.data.img}`} style={{ height: 140 }} alt={item.data.name} />
                                              </div>
                                              <div className="quiz_card_title rounded-b-lg mt-2">
                                                <div className="font-bold" style={{ fontSize: 8 }}>{item.data.name}</div>
                                                <div className=" font-light" style={{ fontSize: 8 }}>ID : {item.asset_id}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                              </section>
                              <Box sx={{ mb: 2 }}>
                                <div>
                                  <Button variant="contained" onClick={() => PushNewSetBags(newAccount)} sx={{ mt: 1, mr: 1 }}>Submit</Button>
                                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>Back</Button>
                                </div>
                              </Box>
                            </div>}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </Box>

            </div> : <div className="flex flex-col w-96 space-y-3">
              <form>
                <label htmlFor="upload">Upload File</label>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  accept=".xlsx,.txt,.json"
                  onChange={readUploadFile}
                />
              </form>

              <div className="text-xs">
                <table className="min-w-full table-fixed divide-y-2 border-collapse divide-current text-center">
                  <thead className="bg-gray-darker dark:bg-gray-light text-gray-light dark:text-gray-darkest uppercase">
                    <tr>
                      <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-center text-xs font-bold tracking-wider">No.</th>
                      <th scope="col" className="border-r-2 w-2/12 px-1 py-3 text-xs font-bold tracking-wider">Account</th>
                      <th scope="col" className="px-1 py-3 w-4/12 text-xs font-bold tracking-wider">Privatekey</th>
                    </tr>
                  </thead>
                  <tbody className={`bg-white  dark:bg-black divide-y-2 divide-current text-gray-darkest dark:text-gray-lightest max-h-96 overflow-y-auto`}>
                    {multipleAcc.map((i, n) => {
                      return (
                        <tr key={n}>
                          <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest dark:border-white text-xs font-extralight">
                            {n + 1}
                          </td>
                          <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest dark:border-white text-xs font-extralight">
                            {i.account}
                          </td>
                          <td className="whitespace-nowrap px-1 border-gray-darkest dark:border-white text-xs font-extralight">
                            {i.secret}
                          </td>
                        </tr>)
                    })}
                  </tbody>
                </table>

              </div>

              <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker p-2 space-y-2">
                <div className="flex flex-row -mt-2">
                  <FormControlLabel
                    control={<Checkbox
                      name="creation"
                      checked={listCreation.creation}
                      onChange={handleChangeAutoCharacter_Creation}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      InputLabelProps={{ shrink: true, }}
                    />}
                    label="Create Account [ Alien Worlds ]"
                    labelPlacement="end"
                  />
                </div>

                <div className={`flex flex-col space-y-3 border-2 border-gray-dark p-3 rounded-lg`} style={{ marginTop: 0 }}>
                  <div className="flex flex-row space-x-1 ">
                    <TextField label="CPU" type="number" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="stake_cpu" value={Number(listCreation.stake_cpu).toFixed(8)} onChange={handleChangeAutoCharacter_Creation} InputProps={{ inputProps: { min: "0", max: "99999", step: "5" } }} />
                    <TextField label="NET" type="number" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="stake_net" value={Number(listCreation.stake_net).toFixed(8)} onChange={handleChangeAutoCharacter_Creation} InputProps={{ inputProps: { min: "0", max: "99999", step: "0.1" } }} />
                    <TextField label="RAM" type="number" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="stake_ram" value={Number(listCreation.stake_ram).toFixed(8)} onChange={handleChangeAutoCharacter_Creation} InputProps={{ inputProps: { min: "0", max: "99999", step: "0.1" } }} />
                  </div>

                  <TextField label="LAND" type="text" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="set_land_id" value={listCreation.set_land_id} onChange={handleChangeAutoCharacter_Creation} />
                  <div className=" text-xs font-light text-red-600">
                    <i>The system will create an account for you with setting up mining equipment and setting up area according to mining number.</i>
                    <li>The character must have mining equipment first.</li>
                    <li>If no stake is required enter 0.00000000 WAX.</li>
                  </div>

                </div>
              </div>

              <div className=" flex flex-row space-x-3 ">

                <button className="flex w-full items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-6 text-xs leading-normal text-white bg-green hover:text-gray-darker focus:bg-green-dark active:bg-green-dark hover:shadow-inner"
                  onClick={() => listCreation.creation ? CreateAccountMultiple() : AddAccountMultiple()}
                >
                  Submit
                </button>

                <a className="flex w-full items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-6 text-xs leading-normal text-white bg-green hover:text-gray-darker focus:bg-green-dark active:bg-green-dark hover:shadow-inner" href="/example_file/Example Template.zip" target="_blank" rel="noreferrer" >
                  <UilFolderDownload size="25" className="cursor-pointer" />
                  Example File
                </a>
              </div>
            </div>}
        </div>
      </Box>
    </Modal>
  </>);
}

export default PushAccount;

