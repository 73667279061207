/* eslint-disable react-hooks/exhaustive-deps */
import { Bar } from "react-chartjs-2";
import React, { useState, useEffect, useContext } from "react";
import { HistoryContext } from "./ModalHistoryMine";
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { dayjs } from "../models/Date";
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
const buildData = ({ chartData }) => ({
    labels: chartData.labels,
    datasets: [
        {
            type: 'bar',
            label: "SUM IN HR. [TLM] ",
            backgroundColor: "rgba(141, 197, 63,1)",
            borderColor: "rgba(141, 197, 63,1)",
            data: chartData.data,
            fill: true,
        },
    ],
});


const StockChart = ({ info, account, usdt }) => {
    const data = buildData(info);
    const { setResHistory } = useContext(HistoryContext);
    const [resJsonTools, setResJsonAll] = useState({
        imgTools: [],
        miningPower: 0,
        chargeTime: 0
    });
    const [highScore, setHighScore] = useState(0)
    const [lowScore, setLowScore] = useState(0)
    const [total, setTotal] = useState(0)


    const GetHistory = async (time) => {

        const base_api = [
            // "https://wax.eosrio.io",
            // "https://wax.greymass.com",
            // "https://wax.cryptolions.io",
            "https://api.wax.greeneosio.com",
            "https://wax.blokcrafters.io",
            "https://waxapi.ledgerwise.io",
            "https://api.waxsweden.org",
        ];
        for (let index = 0; index < base_api.length; index++) {
            try {
                let History = []
                let scores = []
                const res = await fetch(`${base_api[index]}/v2/history/get_actions?account=${account}&skip=0&limit=100&sort=desc&transfer.to=${account}&transfer.from=m.federation&after=${dayjs(time).subtract(1, "day").format("YYYY-MM-DDT17:00:00.000[Z]")}&before=${dayjs(time).format("YYYY-MM-DDT16:59:59.999[Z]")}`)
                const json = await res.json()
                if (json.actions) {
                    const data = json.actions;
                    data.forEach((element, index) => {
                        const oldAmount = json["actions"][index < data.length - 1 ? index + 1 : index]["act"]["data"]["amount"]
                        const newAmount = element["act"]["data"]["amount"]
                        scores.push(newAmount)
                        History.push({
                            "id": json.actions.length - index,
                            "timestamp": element["timestamp"] + "Z",
                            "amount": newAmount,
                            "change": Number((newAmount - oldAmount) * 100 / oldAmount),
                        })
                    });

                    if (data.length > 0) {
                        setHighScore(Math.max.apply(Math, scores))
                        setLowScore(Math.min.apply(Math, scores))
                        setTotal(scores.reduce(function (acc, val) { return acc + val; }, 0))
                        setResHistory(History)
                    }
                    const resJsonAll = []
                    const power = []
                    const imgTools = []
                    if (resJsonTools.imgTools.length === 0) {
                        await fetch("https://wax.greymass.com/v1/chain/get_table_rows", {
                            method: 'POST',
                            "headers": {
                                "accept": "*/*",
                                "accept-language": "en-US,en;q=0.9",
                                "content-type": "text/plain;charset=UTF-8",
                                "sec-fetch-dest": "empty",
                                "sec-fetch-mode": "cors",
                                "sec-fetch-site": "cross-site",
                                "sec-gpc": "1"
                            },
                            "referrer": "https://wax.bloks.io/",
                            "referrerPolicy": "strict-origin-when-cross-origin",
                            "mode": "cors",
                            "credentials": "omit",
                            body: JSON.stringify({
                                "json": true,
                                "code": "m.federation",
                                "scope": "m.federation",
                                "table": "miners",
                                "lower_bound": account,
                                "upper_bound": account
                            })
                        }).then(res => res.json())
                            .then(async (json) => {
                                const resLand = await fetch(`https://api.atomic.greeneosio.com/atomicmarket/v1/assets/${json["rows"][0]["current_land"]}`)
                                const jsonLand = await resLand.json()
                                if (jsonLand.success) {
                                    resJsonAll.push(jsonLand)
                                }
                                await fetch("https://wax.greymass.com/v1/chain/get_table_rows", {
                                    method: 'POST',
                                    "headers": {
                                        "accept": "*/*",
                                        "accept-language": "en-US,en;q=0.9",
                                        "content-type": "text/plain;charset=UTF-8",
                                        "sec-fetch-dest": "empty",
                                        "sec-fetch-mode": "cors",
                                        "sec-fetch-site": "cross-site",
                                        "sec-gpc": "1"
                                    },
                                    "referrer": "https://wax.bloks.io/",
                                    "referrerPolicy": "strict-origin-when-cross-origin",
                                    "mode": "cors",
                                    "credentials": "omit",
                                    body: JSON.stringify({
                                        "json": true,
                                        "code": "m.federation",
                                        "scope": "m.federation",
                                        "table": "bags",
                                        "table_key": "",
                                        "lower_bound": account,
                                        "upper_bound": account,
                                        "index_position": 1,
                                        "key_type": "",
                                        "reverse": false,
                                        "show_payer": false
                                    })
                                }).then(res => res.json())
                                    .then(async (json) => {
                                        const id_bags = json["rows"][0]["items"]
                                        const atomic_endpoint = [
                                            'https://api.atomic.greeneosio.com',
                                        ];
                                        for (const id of id_bags) {
                                            for (let index = 0; index < atomic_endpoint.length; index++) {
                                                try {
                                                    const res = await fetch(`${atomic_endpoint[index]}/atomicmarket/v1/assets/${id}`)
                                                    const json = await res.json()
                                                    if (json.success) {
                                                        resJsonAll.push(json)
                                                        break
                                                    }
                                                } catch (error) {
                                                    console.error(error.message);
                                                }
                                            }
                                        }
                                        resJsonAll.map((img, i) => { imgTools.push(img.data.data.img); return (img.data.data.img) })
                                        //  คำนวน พลังในการขุด
                                        resJsonAll.map((tools, i) => { power.push(tools.data.data.ease / 10); return (tools.data.data.ease) })
                                        let elementPower = 0
                                        for (let index = 1; index < power.length; index++) {
                                            elementPower += power[index];
                                        }

                                        //  คำนวน ดีเลย์
                                        const mining_params = {
                                            delay: 0,
                                            difficulty: 0,
                                            ease: 0,
                                        };

                                        let min_delay = 65535;

                                        for (let b = 1; b < resJsonAll.length; b++) {
                                            if (resJsonAll[b].data.data.delay < min_delay) {
                                                min_delay = resJsonAll[b].data.data.delay;
                                            }
                                            mining_params.delay += resJsonAll[b].data.data.delay;
                                            mining_params.difficulty += resJsonAll[b].data.data.difficulty;
                                            mining_params.ease += resJsonAll[b].data.data.ease / 10;
                                        }

                                        if (resJsonAll.length === 3) {
                                            mining_params.delay -= parseInt(min_delay / 2);
                                        } else if (resJsonAll.length === 4) {
                                            mining_params.delay -= min_delay;
                                        }

                                        mining_params.delay *= resJsonAll[0].data.data.delay / 10;
                                        mining_params.difficulty += resJsonAll[0].data.data.difficulty;

                                        function format(time) {
                                            var mins = ~~((time % 3600) / 60);
                                            var secs = ~~time % 60;
                                            var ret = `${mins} min ${(secs < 10 ? "0" : "") + secs} sec`;
                                            return ret;
                                        }
                                        
                                        setResJsonAll({
                                            imgTools: imgTools,
                                            miningPower: (elementPower * power[0]).toFixed(1),
                                            chargeTime: format(mining_params.delay)
                                        })
                                    })
                                    .catch(err => console.error('error:' + err));
                            })
                            .catch(err => console.error('error:' + err));
                    }
                    return
                }
            } catch (error) {
                console.error(error.message);
            }
        }

    }
    const [selectedDate, handleDateChange] = useState(new Date());

    useEffect(() => {
        GetHistory(selectedDate)
    }, [selectedDate])

    return (
        <>
            <div className="flex flex-col w-full overflow-hidden space-y-2 md:flex-row md:space-x-2 md:space-y-0">
                <div className="w-full md:w-7/12">
                    <Bar data={data} options={{
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: (context) => ("SUN : " + Number(context.parsed.y).toFixed(4) + " TLM"),
                                    title: (context) => (`TIME :  [ ${context[0].label} ]`),
                                    labelTextColor: (context) => '#B5ED67'
                                },
                                titleAlign: "center",
                                displayColors: false

                            }
                        },
                        layout: {
                            padding: 3
                        },
                        scales: {
                            x: {
                                display: true,
                                title: {
                                    display: false,
                                    text: 'Time.'
                                },
                                ticks: {
                                    color: '#659D17',
                                }
                            },
                            y: {
                                // beginAtZero: true,
                                display: true,
                                title: {
                                    display: false,
                                    text: 'value'
                                },
                                ticks: {
                                    color: '#659D17',
                                    callback: function (value, index, values) { return Number(value).toFixed(4) + " TLM"; }
                                }
                            }
                        }
                    }} />
                </div>
                <div className="md:w-5/12 md:flex md:justify-center w-full flex-col">
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate}
                            onChange={(newDate) => {
                                handleDateChange(newDate);
                            }}
                            renderInput={(params) => <TextField variant="standard" fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>
                    <div className="flex w-full text-xs">
                        <div className="flex w-6/12">
                            <div className="flex-1 pr-3 text-left font-semibold">
                                High
                            </div>
                            <div className="px-3 text-right">
                                {Number.parseFloat(highScore).toFixed(4)}
                            </div>
                        </div>
                        <div className="flex w-6/12">
                            <div className="flex-1 px-3 text-left font-semibold whitespace-nowrap">
                                Total TLM
                            </div>
                            <div className="flex-1 pl-3 text-right">
                                {Number.parseFloat(total).toFixed(4)}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full text-xs">
                        <div className="flex w-6/12">
                            <div className="flex-1 pr-3 text-left font-semibold">
                                Low
                            </div>
                            <div className="px-3 text-right">
                                {Number.parseFloat(lowScore).toFixed(4)}
                            </div>
                        </div>
                        <div className="flex w-6/12">
                            <div className="flex-1 px-3 text-left font-semibold whitespace-nowrap">
                                Total USD
                            </div>
                            <div className="pl-3 text-right">
                                {Number(Number(total) * Number(usdt)).toFixed(3)}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-2 my-2">{resJsonTools.imgTools.map((img, i) => { return (<img className="h-16 md:h-20" key={i} src={`https://ipfs.io/ipfs/${img}`} alt={img} />) })}</div>
                    <div className="flex justify-center space-x-6 my-2 text-xs">
                        <div className="font-light flex flex-row space-x-3">
                            Charge Time: {<div className="font-semibold pl-2">{resJsonTools.chargeTime}</div>}
                        </div>
                        <div className="font-light flex flex-row space-x-3">
                            Mining Power: {<div className="font-semibold pl-2">{resJsonTools.miningPower}%</div>}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default StockChart;
