import * as waxjs from "@waxio/waxjs/dist";
const { TextDecoder, TextEncoder } = require("text-encoding/index.js");
const { Api, JsonRpc } = require('eosjs');
const { JsSignatureProvider } = require('eosjs/dist/eosjs-jssig');
const fetch = require('node-fetch/browser.js');

const EosRpc = new JsonRpc('https://wax.greymass.com', { fetch });

const privatekey = '5KJEamqm4QT2bmDwQEmRAB3EzCrCmoBoX7f6MRdrhGjGgHhzUyf';
const signatureProvider = new JsSignatureProvider([privatekey]);
const EosApi = new Api({ rpc: EosRpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

const Wax = new waxjs.WaxJS({
    rpcEndpoint: 'https://wax.greymass.com',
    tryAutoLogin: false,
    freeBandwidth: false
});

const GetWax = async (account) => {
    try {
        const res = await EosRpc.get_table_rows({ json: true, code: "eosio.token", table: "accounts", scope: account, limit: 1, });
        let balance = '0.0000 WAX';
        if (res.rows.length) {
            balance = res.rows[0].balance;
        }
        return { wax: balance };
    } catch (error) {
        window.location.reload()
    }
}

const PushTransaction = async (actions) => {
    console.log(JSON.stringify(actions,null,4));
    return new Promise(async (resolve, reject) => {
        await Wax.api.transact(
            {
                actions,
            },
            {
                blocksBehind: 3,
                expireSeconds: 90,
            }
        ).then(async res => {
            console.log(`PushTransaction ===> `, res)
            resolve(res)
        }).catch(err => {
            console.log(`Failed to push mine results ${err.message}`);
            reject(err);
        });
    });
};
export { Wax, EosApi, EosRpc, fetch, GetWax, PushTransaction };