/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";

/* Set Theme */
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PushAccount from "./components/models/ModalAddAccount";
import MemberShipModal from "./components/models/ModalMemberShip";
import MainProfile from "./pages/MainProfile";
import Login from "./pages/LoginPage";
const InfoMemberShipAW = React.createContext();
const InfoAddAccount = React.createContext();
const InfoAutoFinance = React.createContext();
const ProFileContext = React.createContext();
const MainProFileContext = React.createContext();

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(() => createTheme({ palette: { mode: prefersDarkMode ? 'dark' : 'light', }, }), [prefersDarkMode],);

  /* Set PopUp */
  const [popAddAccount, setPopAddAccount] = useState(false);
  const [popMemberShipAW, setPopMemberShipAW] = useState(false)

  /* Set useState System */
  const [timeServer, setTimeServer] = useState([]);
  const [response, setResponse] = useState([]);
  const [masterKey, setMasterKey] = useState([]);
  const [totalTlm, setTotalTlm] = useState(0);
  const [totalWax, setTotalWax] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [statusLogin, setLogin] = useState(false);
  const [WAXPUSDT, setWAXPUSDT] = useState(0)
  const [TLMUSDT, setTLMUSDT] = useState(0)
  const [valueKey, setValueKey] = useState({
    status: false,
    masterKey: "XXXXXXXXXX",
    coin: 0,
    created_at: "",
    trx: ""
  });

  useEffect(() => {
    let tlm = [];
    let wax = [];
    let rewards = [];
    response.map((item) => {
      tlm.push(parseFloat(item.tlm));
      wax.push(parseFloat(item.wax));
      rewards.push(parseFloat(item.rewards));
      return item;
    });
    setTotalTlm(
      tlm.reduce(function (acc, val) {
        return acc + val;
      }, 0)
    );
    setTotalRewards(
      rewards.reduce(function (acc, val) {
        return acc + val;
      }, 0)
    );
    setTotalWax(
      wax.reduce(function (acc, val) {
        return acc + val;
      }, 0)
    );
  }, [response]);


  return (
    <main>
      <ThemeProvider theme={theme}>
        {statusLogin === true ?
          <ProFileContext.Provider value={{ response, TLMUSDT, setLogin, timeServer }}>
            <InfoAddAccount.Provider value={{ popAddAccount, setPopAddAccount, }}>
              <PushAccount />
            </InfoAddAccount.Provider>
            <InfoMemberShipAW.Provider value={{ popMemberShipAW, setPopMemberShipAW, masterKey, setMasterKey, valueKey, setValueKey }}>
              <MemberShipModal />
            </InfoMemberShipAW.Provider>
            <MainProFileContext.Provider value={{ popAddAccount, setPopAddAccount, popMemberShipAW, setPopMemberShipAW, masterKey, setMasterKey, setResponse, setTimeServer, totalTlm, totalWax, totalRewards, TLMUSDT, setTLMUSDT, WAXPUSDT, setWAXPUSDT, setLogin }}>
              <MainProfile />
            </MainProFileContext.Provider>
          </ProFileContext.Provider>
          :
          <ProFileContext.Provider value={{ setLogin }}>
            <Login />
          </ProFileContext.Provider>
        }
      </ThemeProvider>
    </main >
  );
}
export { MainProFileContext, ProFileContext, InfoMemberShipAW, InfoAddAccount, InfoAutoFinance };
export default App;
