import React, { useContext, useState, useEffect } from "react";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';

import StockChart from "./StockChart";
import { dayjs } from "./Date";
import { ModalContext } from "../content/TableProfile";

const HistoryContext = React.createContext();

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    // overflow: 'scroll',
    maxWidth: '100%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #8DC53F',
};

export default function HistoryMineModal(props) {

    const [data, setData] = useState({
        chartData: {
            labels: [],
            data: [],
        },
    })

    const { TLMUSDT, showModalGetHistory, setShowModalGetHistory } = useContext(ModalContext);

    const [resHistory, setResHistory] = useState([]);

    useEffect(() => {
        let chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const groups = resHistory.reduce((map, el) => {
            const dateKey = dayjs.utc(el.timestamp).local().format("HH")
            map.set(dateKey, map.get(dateKey) ? map.get(dateKey).concat(el.amount) : [el.amount]);
            return map;
        }, new Map());

        for (const iterator of groups) {
            const [key, val] = iterator;
            chartData[Number(key)] = Number(val.reduce((acc, val) => { return acc + val; }, 0)).toFixed(4)
        }

        setData({
            chartData: {
                labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
                data: chartData,
            },
        })
    }, [resHistory])

    return (
        <>
            <Modal
                open={showModalGetHistory.show}
                onClose={() => setShowModalGetHistory({ ...showModalGetHistory, show: !showModalGetHistory.show })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500, }}
            >
                <Box sx={style} className={"text-gray-darker dark:text-gray-light bg-gray-lighter dark:bg-gray-darker rounded-xl w-full md:w-auto p-1 overflow-auto"}>
                    <HistoryContext.Provider value={{ resHistory, setResHistory }}>
                        <div className="w-full">
                            <div className="flex dark:border-gray-darkest">
                                <StockChart info={data} account={showModalGetHistory.wax} usdt={TLMUSDT} />
                            </div>
                        </div>
                        {resHistory.length === 0 ? null :
                            <div className="shadow overflow-y-auto border-2 border-gray-darker dark:border-gray-light rounded-lg" style={{ maxHeight: 350 }}>
                                <table className="min-w-full border-collapse text-center">
                                    <thead className="bg-gray-darker dark:bg-gray-light text-gray-lightest dark:text-gray-darkest uppercase ">
                                        <tr>
                                            <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-center text-xs font-bold tracking-wider">#</th>
                                            <th scope="col" className="border-r-2 w-3/12 px-1 py-3 text-xs font-bold tracking-wider">Mining Attempts</th>
                                            <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-xs font-bold tracking-wider" >TLM</th>
                                            <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-xs font-bold tracking-wider">USD</th>
                                            <th scope="col" className=" w-1/12 py-3 text-xs font-bold tracking-wider">% Change</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-lighter  dark:bg-gray-darkest divide-y divide-gray-200">
                                        {resHistory.map((History, i) => {
                                            const colorBounty = History.change > 0 ? "green" : History.change === 0 ? "gray-light" : "danger";
                                            return (<tr key={i}>
                                                <td className="whitespace-nowrap px-1 border-r-2 border-green-600 text-xs">
                                                    {History.id}
                                                </td>
                                                <td className="whitespace-nowrap px-1 border-r-2 border-green-600 text-xs">
                                                    {dayjs.utc(History.timestamp).local().format("YYYY-MM-DD HH:mm:ss")}
                                                </td>
                                                <td className="whitespace-nowrap px-1 border-r-2 border-green-600 text-xs">
                                                    {Number(History.amount).toFixed(4)}
                                                </td>
                                                <td className="whitespace-nowrap px-1 border-r-2 border-green-600 text-xs">
                                                    $ {Number(Number(History.amount).toFixed(4) * Number(TLMUSDT)).toFixed(3)}
                                                </td>
                                                <td className="flex flex-nowrap px-1 border-r-2 border-green-600 text-xs">
                                                    <span className={`bg-${colorBounty} bg-opacity-60 dark:text-gray-light text-gray-darkest text-xs rounded py-0.5 m-1 w-full font-medium`}>{History.change > 0 ? "+" : null}{Number(History.change).toFixed(4)}%</span>
                                                </td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </HistoryContext.Provider>
                </Box>
            </Modal>
        </>

    );
}
export { HistoryContext };
