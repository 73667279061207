const fetch = require("node-fetch");

const SetStatus = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`/status_account/${account}`,{
        method: "GET",
        headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
      });
      const json = await res.json();
      if (res.status === 200) {
        resolve(json);
        return;
      }
    } catch (err) {
      console.error("error:" + err);
    }
  });
};
export { SetStatus };
