import URL_API from "./Constants";

const fetch = require("node-fetch");

const ReBootServer = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await fetch(`/reboot`, { headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), }, });
      window.location.reload()
    } catch (err) {
      console.error("error:" + err);
    }
  });
};
export { ReBootServer };
