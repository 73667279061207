/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState, useMemo, useEffect } from "react";
import { dayjs } from "../models/Date";
import { SetStatus } from "../models/SetStatus";
import { ProFileContext } from "../../App";
import { toast } from "react-toastify";
import { UilSlidersV, UilTrashAlt, UilChartBar, UilPlay, UilPause, UilClockNine } from '@iconscout/react-unicons'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';

// import "react-toastify/dist/ReactToastify.css";

import HistoryMineModal from "../models/ModalHistoryMine";
import PushTransactionModal from '../models/ModalPushTransaction';
import SetTimeModal from "../models/ModalTime";
import MiningToolsModal from "../models/ModalMiningTools";

const ModalContext = React.createContext();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '3px solid #D72D2D',
  width: 300,
  height: 260
};

function TableProfile() {

  const { response, setResponse, TLMUSDT } = useContext(ProFileContext);

  const [hideAccount, setHideAccount] = useState(false);

  const Hx = {
    1: "Novice",
    2: "Initiate",
    3: "Associate",
    4: "Peacekeeper",
    5: "Expert",
    6: "SkyRider",
    7: "Master",
    8: "Champion",
    9: "Grand Master",
    10: "Oshi Initiate",
    11: "Infinity"
  }

  // "total_points":{"status":false,"level_claimed":4,"next_level_points":78530,"points":5770,"class":"Associate"}

  const onProfileWaxDelete = async (account) => {

    setShowModalDelAccount({
      show: false,
      wax: "xxxxx.wam"
    })

    const SendPushTransaction = new Promise(async (resolve, rejected) => {
      await fetch(`/accounts/${account}`, { method: 'DELETE', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), }, })
          .then(res => res.json())
          .then(json => resolve(json))
          .catch(err => rejected(err));
    });

    toast.promise(
        SendPushTransaction,
        {
          pending: 'Promise is pending',
          success: {
            render({ data }) {
              return `Succeed : ${account}`
            },
          },
          error: {
            render({ data }) {
              // When the promise reject, data will contains the error
              return `Failed : ${account}`
            }
          }
        }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
    )
  }

  const [showModalSetTime, setShowModalSetTime] = useState({
    show: false,
    wax: "xxxxx.wam",
  })

  const [showModalGetHistory, setShowModalGetHistory] = useState({
    show: false,
    wax: "xxxxx.wam"
  })

  const [showModalDelAccount, setShowModalDelAccount] = useState({
    show: false,
    wax: "xxxxx.wam"
  })

  const [showModalMiningTools, setShowModalMiningTools] = useState({
    show: false,
    wax: "xxxxx.wam"
  })

  const [pushTrx, setPushTrx] = useState({
    show: false,
    wax: "xxxxx.wam"
  })



  const [data24Hr, setData24Hr] = useState([])
  const [swapPrice, setSwapPrice] = useState({
    tlm: {
      "input": 0,
      "output": 0,
      "minReceived": 0,
      "maxSent": 0,
      "priceImpact": 0,
      "memo": "swapexactin#0#alienscripts#0.0 TLM@eosio.token#0",
      "route": [
        0
      ],
      "executionPrice": {
        "numerator": "28777815",
        "denominator": "10000"
      }
    },
    wax: {
      "input": 0,
      "output": 0,
      "minReceived": 0,
      "maxSent": 0,
      "priceImpact": 0,
      "memo": "swapexactin#0#alienscripts#0.0 WAX@eosio.token#0",
      "route": [
        0
      ],
      "executionPrice": {
        "numerator": "28777815",
        "denominator": "10000"
      }
    }
  });
  const [waxPriceRam, setWaxPriceRam] = useState({ bytes: 0, wax: 0, });
  const [resourcesIndex, setResourcesIndex] = useState(0)

  const ModalDel = useMemo(() => {
    return (<>
      <Modal
          open={showModalDelAccount.show}
          onClose={() => setShowModalDelAccount({
            show: false,
            wax: "xxxxx.wam"
          })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
      >
        <Box sx={style} className="text-gray-darker bg-gray-lighter rounded-xl font-semibold mx-auto outline-none p-3">
          <div className="text-center p-5 flex-auto justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 flex items-center text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
            <p className="text-sm text-gray-dark px-2">{showModalDelAccount.wax}</p>
            <div className="p-3  mt-2 text-center space-x-4 md:block">
              <button className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-dark rounded-full hover:shadow-lg hover:bg-gray-lightest"
                      onClick={() => setShowModalDelAccount({
                        show: false,
                        wax: "xxxxx.wam"
                      })}
              >
                Cancel
              </button>
              <button className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600"
                      onClick={() => onProfileWaxDelete(showModalDelAccount.wax)}
              >Delete</button>
            </div>

          </div>
        </Box>
      </Modal>
    </>)
  }, [showModalDelAccount])

  return (

      <div>
        <ModalContext.Provider value={{ response, swapPrice, setSwapPrice, waxPriceRam, setWaxPriceRam, data24Hr, setData24Hr, pushTrx, setPushTrx, TLMUSDT, showModalGetHistory, setShowModalGetHistory, showModalSetTime, setShowModalSetTime, showModalMiningTools, setShowModalMiningTools }}>
          <PushTransactionModal />
          <HistoryMineModal account={showModalGetHistory.wax} />
          <SetTimeModal />
          <MiningToolsModal />
        </ModalContext.Provider>
        {ModalDel}
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-28 ">
            <div className="p-2 align-middle inline-block min-w-full sm:px-6 ">
              <div className="shadow border-2 border-gray-200 rounded-lg" >
                <table className="min-w-full table-fixed divide-y-2 border-collapse divide-current text-center">
                  <thead className="bg-gray-darker dark:bg-gray-light text-gray-light dark:text-gray-darkest uppercase">
                  <tr>
                    <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-center text-xs font-bold tracking-wider">No.</th>
                    <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-xs font-bold tracking-wider whitespace-nowrap cursor-pointer" onClick={() => setHideAccount(!hideAccount)}> Account </th>
                    <th scope="col" className="border-r-2 w-3/12 px-1 py-3 text-xs font-bold tracking-wider">Resource</th>
                    <th scope="col" className="border-r-2 w-2/12 px-1 py-3 text-xs font-bold tracking-wider">Land</th>
                    <th scope="col" className="border-r-2 w-3/12 px-1 py-3 text-xs font-bold tracking-wider hidden md:table-cell">Tools</th>
                    <th scope="col" className="border-r-2 w-1/12 py-3 text-xs font-bold tracking-wider">Last mine</th>
                    <th scope="col" className="border-r-2 w-1/12 px-1 py-3 text-xs font-bold tracking-wider">Wallet</th>
                    <th scope="col" className="border-r-2 w-full px-1 py-3 text-xs font-bold tracking-wider">Status</th>
                    <th scope="col" className="px-1 py-3 w-12/12 text-xs font-bold tracking-wider">Action</th>
                  </tr>
                  </thead>
                  <tbody className={`bg-white  dark:bg-black divide-y-2 divide-current text-gray-darkest dark:text-gray-lightest`}>
                  {response.map((tableProfile, i) => {
                    const bounty_last = Number.parseFloat(
                        tableProfile.last_mine.bounty
                    ).toFixed(4);
                    const colorBounty =
                        bounty_last > 0.0005 ? "bg-green" : "bg-danger";
                    const Resources = [
                      <>
                        <div className="w-full bg-gray-light rounded text-left" >
                          <div className={`${Math.round((tableProfile.resources.cpu_limit.used / tableProfile.resources.cpu_limit.max) * 100) > 75 ? Math.round((tableProfile.resources.cpu_limit.used / tableProfile.resources.cpu_limit.max) * 100) > 90 ? "bg-danger" : "bg-warning" : "bg-green"} rounded `}
                               style={{
                                 width: Math.round((tableProfile.resources.cpu_limit.used / tableProfile.resources.cpu_limit.max) * 100) + "%",
                                 maxWidth: "100%",
                                 fontSize: 10,
                               }}
                          >
                            <span className="px-1">CPU {Math.round((tableProfile.resources.cpu_limit.used / tableProfile.resources.cpu_limit.max) * 100)}% ( {Number(tableProfile.resources.cpu_limit.used / 1000).toFixed(1)} ms / {Number(tableProfile.resources.cpu_limit.max / 1000).toFixed(1)} ms )</span>
                          </div>
                        </div>
                        <span className="text-gray-darkest dark:text-gray-lighter" style={{ fontSize: 10 }}>Stake : {tableProfile.resources.total_resources.cpu_weight}</span>
                      </>,
                      <>
                        <div className="w-full bg-gray-light rounded text-left" >
                          <div className={`${Math.round((tableProfile.resources.ram_limit.used / tableProfile.resources.ram_limit.max) * 100) > 75 ? Math.round((tableProfile.resources.ram_limit.used / tableProfile.resources.ram_limit.max) * 100) > 90 ? "bg-danger" : "bg-warning" : "bg-green"} rounded `}
                               style={{
                                 width: Math.round((tableProfile.resources.ram_limit.used / tableProfile.resources.ram_limit.max) * 100) + "%",
                                 maxWidth: "100%",
                                 fontSize: 10,
                               }}
                          >
                            <span className="px-1">RAM {Math.round((tableProfile.resources.ram_limit.used / tableProfile.resources.ram_limit.max) * 100)}% ( {Number(tableProfile.resources.ram_limit.used / 1000).toFixed(1)} KB / {Number(tableProfile.resources.ram_limit.max / 1000).toFixed(1)} KB )</span>
                          </div>
                        </div>
                        <span className="text-gray-darkest dark:text-gray-lighter" style={{ fontSize: 10 }}>Stake : {tableProfile.resources.total_resources.ram_bytes} Bytes</span>
                      </>,
                      <>
                        <div className="w-full bg-gray-light rounded text-left" >
                          <div className={`${Math.round((tableProfile.resources.net_limit.used / tableProfile.resources.net_limit.max) * 100) > 75 ? Math.round((tableProfile.resources.net_limit.used / tableProfile.resources.net_limit.max) * 100) > 90 ? "bg-danger" : "bg-warning" : "bg-green"} rounded `}
                               style={{
                                 width: Math.round((tableProfile.resources.net_limit.used / tableProfile.resources.net_limit.max) * 100) + "%",
                                 maxWidth: "100%",
                                 fontSize: 10,
                               }}
                          >
                            <span className="px-1">NET {Math.round((tableProfile.resources.net_limit.used / tableProfile.resources.net_limit.max) * 100)}% ( {Number(tableProfile.resources.net_limit.used / 1000).toFixed(1)} KB / {Number(tableProfile.resources.net_limit.max / 1000).toFixed(1)} KB )</span>
                          </div>
                        </div>
                        <span className="text-gray-darkest dark:text-gray-lighter m-0" style={{ fontSize: 10 }}>Stake : {tableProfile.resources.total_resources.net_weight}</span>
                      </>,
                      <>
                        <div className="w-full bg-gray-light rounded text-left" >
                          <div className={` bg-indigo-500 rounded`}
                               style={{
                                 width: Math.round((tableProfile.total_points.points / tableProfile.total_points.next_level_points) * 100) + "%",
                                 maxWidth: "100%",
                                 fontSize: 10,
                               }}
                          >
                            <span className="px-1">Point NFT {Math.round((tableProfile.total_points.points / tableProfile.total_points.next_level_points) * 100)}% ( {Number(tableProfile.total_points.points / 10).toFixed(1)} / {Number(tableProfile.total_points.next_level_points / 10).toFixed(1)} )</span>
                          </div>
                        </div>
                        <span className="text-gray-darkest dark:text-gray-lighter m-0" style={{ fontSize: 10 }}>Next Rank : {Hx[tableProfile.total_points.class]} → {Hx[tableProfile.total_points.class + 1]}</span>
                      </>
                    ];

                    return (
                        <tr key={i}>
                          <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest dark:border-white text-xs font-semibold ">
                            {i + 1}
                          </td>
                          <td className={`whitespace-nowrap px-1 border-r-2 border-gray-darkest dark:border-white text-xs font-semibold `}>
                            <a
                                href={`https://wax.bloks.io/account/${tableProfile.account}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                              {hideAccount === false ? `${'***'+tableProfile.account.substring(3, 9)+'***'}` : tableProfile.account}
                            </a>
                          </td>
                          <td className="whitespace-nowrap p-1 border-r-2 border-gray-darkest dark:border-white text-xs text-gray-darkest font-semibold ">
                            <div className="flex flex-col items-center justify-center space-y-0.5 cursor-pointer" onClick={() => { setResourcesIndex(resourcesIndex + 1) }}>
                              {Resources[resourcesIndex % Resources.length]}
                            </div>
                          </td>

                          <td className="flex border-r-2 font-semibold border-gray-darkest dark:border-white text-xs items-center justify-center p-1 sm:space-x-1">
                            <div className="hidden md:table-cell"><img className="items-center justify-center space-x-1 min-h-14 h-16 max-h-20" src={`https://ipfs.io/ipfs/${tableProfile.land.data[0].template.immutable_data.img}`} alt={tableProfile.land.data[0].name} /></div>
                            <div className="flex-col whitespace-nowrap">
                              <div className="break-all" style={{ fontSize: "10px" }}>
                                <font className="dark:text-green">{tableProfile.land.data[0].name}</font>
                              </div>
                              <div className="break-all" style={{ fontSize: "10px" }}>
                                <font className="dark:text-green">ID :</font> {tableProfile.land.data[0].asset_id} ({tableProfile.land.data[0].immutable_data.x},{tableProfile.land.data[0].immutable_data.y})
                              </div>
                              <div className="break-all" style={{ fontSize: "10px" }}>
                                <font style={{ fontSize: "10px", color: "#0ed4a8" }}>Commission : </font>{parseFloat(tableProfile.land.data[0].mutable_data.commission / 100).toFixed(2)}%
                              </div>
                              <div className="break-all" style={{ fontSize: "10px" }}>
                                <font style={{ fontSize: "10px", color: "#d9a555" }}>Land Rating : </font>{parseFloat(tableProfile.land.data[0].mutable_data.landrating / 10000).toFixed(4)}%
                              </div>
                            </div>
                          </td>
                          {/* tools */}

                          <td className="px-1 border-r-2 border-gray-darkest dark:border-white hidden md:table-cell">
                            <div className="flex items-center justify-center">
                              {tableProfile.tools.data.map((item, i) => {
                                return (
                                    <div className="flex-row" key={item.asset_id}>

                                      <Tooltip
                                          title={
                                            <div className=" uppercase text-center" style={{ whiteSpace: 'pre-line' }}>ID : <font className=" font-extrabold" style={{ color: "#FF0000" }}>{item.asset_id}</font><br />name : {item.template.immutable_data.name}</div>
                                          }
                                          placement="top" arrow>
                                        <img className="items-center justify-center space-x-1 min-h-14 h-16 max-h-20" src={`https://ipfs.io/ipfs/${item.template.immutable_data.img}`} alt={item.name} />
                                      </Tooltip>
                                    </div>
                                )
                              })}
                            </div>
                          </td>

                          <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest dark:border-white justify-center items-center">
                            <a
                                href={`https://wax.bloks.io/transaction/${tableProfile.last_mine.trx}`}
                                target="_blank"
                                rel="noreferrer"
                                className={`${colorBounty} flex w-full text-gray-darkest rounded py-0.5 px-2 font-extrabold text-xs`}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "center"
                                }}
                            >
                              {bounty_last} TLM
                            </a>
                            <p style={{ fontSize: 10 }}>{dayjs.utc(tableProfile.last_mine.timestamp).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                          </td>
                          <td className="whitespace-nowrap p-1 border-r-2 border-gray-darkest dark:border-white text-xs">
                            <div className="flex w-full text-xs">
                              <div className=" text-right dark:text-warning-light font-bold">
                                WAX
                              </div>
                              <div className="flex-1 text-right ml-1 font-semibold">
                                {Number.parseFloat(tableProfile.wax).toFixed(8)}
                              </div>
                            </div>
                            <div className="flex w-full text-xs">
                              <div className="text-right dark:text-success font-bold">
                                TLM
                              </div>
                              <div className="flex-1 text-right ml-1 font-semibold">
                                {Number.parseFloat(tableProfile.tlm).toFixed(4)}
                              </div>
                            </div>
                            <div className="flex w-full text-xs">
                              <div className="text-right dark: text-indigo-500 font-bold uppercase">
                                Rewards
                              </div>
                              <div className="flex-1 text-right ml-1 font-semibold">
                                {Number.parseFloat(tableProfile.rewards).toFixed(4)}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest dark:border-white text-xs">
                            <p className="uppercase truncate px-2 font-semibold">
                              {tableProfile.status_worker}
                            </p>
                          </td>
                          <td className="px-3 border-r-2 border-gray-darkest dark:border-white text-xs space-y-1">
                            <div className="flex flex-row justify-center items-center space-x-1 px-2">
                              <Tooltip title="History Mined" placement="top" arrow>
                                <button
                                    className="text-purple-500 bg-transparent border-2 border-solid rounded-md border-purple-500  "
                                    type="button"
                                    onClick={() => setShowModalGetHistory({
                                      show: !showModalGetHistory.show,
                                      wax: tableProfile.account
                                    })}
                                >
                                  <UilChartBar size="25" className="cursor-pointer" />
                                </button>
                              </Tooltip>

                              <Tooltip title="Swap | Transfer | Stake | Setup Tools" placement="top" arrow>
                                <button
                                    className="text-purple-500 bg-transparent border-2 border-solid rounded-md border-purple-500 "
                                    type="button"
                                    onClick={() => setPushTrx({
                                      show: !pushTrx.show,
                                      account: tableProfile.account,
                                      tlm: tableProfile.tlm,
                                      wax: tableProfile.wax,
                                    })}
                                >
                                  <UilSlidersV size="25" className="cursor-pointer" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Set Mining" placement="top" arrow>
                                <img
                                    className="bg-transparent border-2 border-solid rounded-md border-purple-500 cursor-pointer"
                                    src={require("../../assets/img/miningtools.svg").default}
                                    alt="mining"
                                    type="button"
                                    style={{ width: "1.8rem", height: "1.8rem" }}
                                    onClick={() => setShowModalMiningTools({
                                      show: !showModalMiningTools.show,
                                      wax: tableProfile.account,
                                      resourcesType: "userResources",
                                      mine_bucket: "0.0000",
                                      mine_rarity: "Abundant",
                                      status: false,
                                      time_out: 120,
                                      statusDelay: false,
                                      timeDelay: 30,
                                    })}
                                />
                              </Tooltip>
                              <Tooltip title="Play | Pause Script" placement="top" arrow>
                                <button
                                    className="text-purple-500 bg-transparent border-2 border-solid rounded-md border-purple-500 "
                                    type="button"
                                    onClick={() => SetStatus(tableProfile.account)}
                                >
                                  {tableProfile.status_login ? <UilPlay size="25" className="cursor-pointer" /> : <UilPause size="25" className="cursor-pointer" />}
                                </button>
                              </Tooltip>

                              <Tooltip title="Delete Account Wax" placement="top" arrow>
                                <button
                                    className="text-purple-500 bg-transparent border-2 border-solid rounded-md border-purple-500 "
                                    type="button"
                                    onClick={() => setShowModalDelAccount({
                                      show: true,
                                      wax: tableProfile.account
                                    })}
                                >
                                  <UilTrashAlt size="25" className="cursor-pointer" />
                                </button>
                              </Tooltip>

                            </div>
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export { ModalContext };
export default TableProfile;
