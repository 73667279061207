/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box, Modal, TextField, Checkbox, InputAdornment, Radio, RadioGroup, FormControlLabel, FormControl, IconButton, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AnchorLink from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'
import xlsx from "xlsx";

import randomKeyGenerator from "random-key-generator";
import { UilCopy } from '@iconscout/react-unicons';
import { toast } from "react-toastify";

import { Transition } from "@headlessui/react";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InfoMemberShipAW } from "../../App";
import { Wax, GetWax, PushTransaction } from "./ApiWax";

import generatePayload from 'promptpay-qr'

const QRCode = require('qrcode.react');

const { PrivateKey } = require('eosjs/dist/PrivateKey');

//1. Import coingecko-api
const CoinGecko = require('coingecko-api');

//2. Initiate the CoinGecko API Client
const CoinGeckoClient = new CoinGecko();

const fetch = require('node-fetch');

const style = {
    position: 'absolute',
    // overflow: 'scroll',
    maxHeight: '95%',
    maxWidth: '95%',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #8DC53F',
};


export default function MemberShipModal() {


    // ------------------------------------------- Anchor API --------------------------------------------- //
    const transport = new AnchorLinkBrowserTransport()
    const link = new AnchorLink({
        transport,
        chains: [
            {
                chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
                nodeUrl: 'https://wax.greymass.com',
            }
        ],
    })

    // ---------------------------------------------------------------------------------------- //
    const { popMemberShipAW, setPopMemberShipAW, masterKey, } = useContext(InfoMemberShipAW);

    const [waxAccount, setWaxAccount] = useState(null)

    const [rate, setRate] = useState({
        tlm: {
            "input": 0,
            "output": 0,
            "minReceived": 0,
            "maxSent": 0,
            "priceImpact": 0,
            "memo": "swapexactin#0#alienscripts#0.0 TLM@eosio.token#0",
            "route": [
                0
            ],
            "executionPrice": {
                "numerator": "28777815",
                "denominator": "10000"
            }
        },
        wax: {
            "input": 0,
            "output": 0,
            "minReceived": 0,
            "maxSent": 0,
            "priceImpact": 0,
            "memo": "swapexactin#0#alienscripts#0.0 WAX@eosio.token#0",
            "route": [
                0
            ],
            "executionPrice": {
                "numerator": "28777815",
                "denominator": "10000"
            }
        }
    })

    const [memoPackage, setMemoPackage] = useState('25');

    const [promptpay, setPromptpay] = useState({
        status: false,
        amount: 100,
        price: 0,
        depositAmount: 0,
        dateTransfer: "2022-02-03T15:59"
    });

    // const [qrCode, setQrCode] = useState("sample");

    const [delegated, setDelegated] = useState({
        stake: true,
        delegated_to: null
    })

    const [activeTab, setActiveTab] = useState(0)

    const [activeTabMember, setActiveTabMember] = useState(0)

    const [isOpen, setIsOpen] = useState(false);

    const [resProfile, setResProfile] = useState({
        "cpu_limit": {
            "used": 0,
            "available": 0,
            "max": 1
        },
        "net_limit": {
            "used": 0,
            "available": 0,
            "max": 1
        },
        "ram_limit": {
            "used": 0,
            "available": 0,
            "max": 1
        },
        "total_resources": {
            "owner": "alienscripts",
            "net_weight": "0.00000000 WAX",
            "cpu_weight": "0.00000000 WAX",
            "ram_bytes": 0
        },
    })

    const [valueKey, setValueKey] = useState({
        status: false,
        masterKey: "XXXXXXXXXXX",
        coin: 0,
        created_at: "",
        trx: ""
    });

    const [stakeAll, setStakeAll] = useState({
        stake_cpu: 10,
        stake_net: 0.5,
        stake_ram: 0.5
    });

    const [autoSwap, setAutoSwap] = useState({
        status: false,
        amount: 0,
        rate: 0
    });


    const [autoTransfer, setAutoTransfer] = useState({
        status: false,
        type: "wax",
        account: "",
        memo: "",
        amount: 0,
    });

    const [privatekey, setPrivatekey] = useState({
        status: false,
        account: "",
        key: "",
        showPrivatekey: false,
    });

    const readUploadFile = (e) => {
        // console.log(e.target.value);
        const typeFile = e.target.value
        e.preventDefault();
        const reader = new FileReader();

        if (typeFile.includes(".txt")) {
            reader.onload = function (e) {
                let text = e.target.result;
                // console.log(e.target.result.substring(0));
                const txtArr = text.split('\n').map(entry => {
                    const obj = {}
                    entry.split('\n').forEach(keyValue => {
                        const split = keyValue.split("//");
                        obj["account"] = split[0];
                        obj["email"] = split[1];
                        obj["password"] = split[2];
                        obj["secret"] = split[3];
                    })
                    const jsonData = JSON.stringify(obj).replace(/\s/g, "")
                    return JSON.parse(jsonData)
                })
                console.log('txtArray => ', txtArr)
                let list_account = []
                txtArr.map((i) => {
                    list_account.push({
                        account_name: i.account,
                        cpu_weight: stakeAll.stake_cpu,
                        net_weight: stakeAll.stake_net,
                        ram_weight: stakeAll.stake_ram
                    })

                })
                setDelegated({
                    stake: true,
                    delegated_to: list_account
                })
                // setDelegated(txtArr);
            };
            reader.readAsText(e.target.files[0]);
        } else if (typeFile.includes(".xlsx")) {
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                console.log('xlsxArray => ', json)
                // setDelegated(json);
                let list_account = []
                json.map((i) => {
                    list_account.push({
                        account_name: i.account,
                        cpu_weight: stakeAll.stake_cpu,
                        net_weight: stakeAll.stake_net,
                        ram_weight: stakeAll.stake_ram
                    })

                })
                setDelegated({
                    stake: true,
                    delegated_to: list_account
                })
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        } else {
            reader.readAsText(e.target.files[0], "UTF-8");
            reader.onload = (e) => {
                console.log("jsonArray", JSON.parse(e.target.result));
                const json_account = JSON.parse(e.target.result)
                let list_account = []
                json_account.map((i) => {
                    list_account.push({
                        account_name: i.account,
                        cpu_weight: stakeAll.stake_cpu,
                        net_weight: stakeAll.stake_net,
                        ram_weight: stakeAll.stake_ram
                    })

                })
                // setDelegated(JSON.parse(e.target.result));
                setDelegated({
                    stake: true,
                    delegated_to: list_account
                })
            };
        }
    }



    const GeneratorKey = () => {
        setValueKey((prevContext) => {
            return {
                ...prevContext,
                "masterKey": randomKeyGenerator(10).toUpperCase(),
            };
        });
    };

    const onValueChange = (event) => {
        const { name, value } = event.target;
        setValueKey((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
            };
        });
    };

    const handleChangeAutoTransfer = (event) => {
        const { name, value } = event.target;
        setAutoTransfer((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
                "status": event.target.checked
            };
        });
    };

    const handleChangeAutoTransferType = (event) => {
        setAutoTransfer((prevKey) => {
            return {
                ...prevKey,
                "type": event.target.value
            };
        });
    };

    const handleChangeAutoSwap = (event) => {
        const { name, value } = event.target;
        setAutoSwap((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
                "status": event.target.checked
            };
        });
    };

    const handleChangeStakeAll = (event) => {
        const { name, value } = event.target;
        setStakeAll((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
            };
        });
    };

    const handleChangePromptpay = (event) => {
        const { name, value } = event.target;
        setPromptpay((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
            };
        });
    };

    const handleChangePrivateKey = (event) => {
        const { name, value } = event.target;
        setPrivatekey((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
                "status": event.target.checked
            };
        });
    };
    const handleClickShowPrivateKey = () => {
        setPrivatekey({
            ...privatekey,
            showPrivatekey: !privatekey.showPrivatekey,
        });
    };

    const handleMouseDownPrivateKey = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (privatekey.key === "") return
        async function GetAuthorizers() {
            await fetch("https://wax.greymass.com/v1/chain/get_accounts_by_authorizers", {
                "headers": {
                    "accept": "*/*",
                    "accept-language": "en-US,en;q=0.9",
                    "content-type": "text/plain;charset=UTF-8",
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "cross-site",
                    "sec-gpc": "1"
                },
                "referrer": "https://wax.bloks.io/",
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": JSON.stringify({ "accounts": [], "keys": [PrivateKey.fromString(privatekey.key).getPublicKey().toString()] }),
                "method": "POST",
                "mode": "cors",
                "credentials": "omit"
            }).then(res => res.json())
                .then(json => {
                    getResPrivatekey(json.accounts[0].account_name)
                    setPrivatekey({
                        ...privatekey,
                        account: json.accounts[0].account_name,
                    });
                })
                .catch(err => console.error('error:' + err));;
        }
        GetAuthorizers()
    }, [privatekey.key])

    const GetMember = async (Key) => {
        if (Key !== undefined) {
            return new Promise(async (resolve, reject) => {
                const json = {
                    master_key: 'G0CCU8EPGV',
                    coin: 9999,
                    created_at: '2021-08-01T06:21:09.000000Z'
                };

                resolve(
                    {
                        masterKey: json.master_key,
                        coin: json.coin,
                        created_at: json.created_at
                    }
                )
                setValueKey({
                    masterKey: json.master_key,
                    coin: json.coin,
                    created_at: json.created_at
                })

            });
        }
    }

    const getPriceTLM = () => {
        return new Promise(async (resolve, rejected) => {
            await CoinGeckoClient.simple.price({
                ids: ['alien-worlds'],
                vs_currencies: ['thb'],
            }).then((result) => {
                resolve(result["data"]["alien-worlds"]["thb"])
            }).catch((err) => {
                console.error(err)
            });
        });
    }

    const SetMasterKey = async (token) => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch(`/verify`, {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('token'),
                    "Content-type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify({ token: token })
            }).then(res => res.json())
                .then(json => {
                    if (json.verifyMember === true) {
                        resolve(true)
                        window.sessionStorage.setItem("login", false);
                        window.location.reload()
                    } else {
                        rejected(false)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Enable Key : ${token}`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const SetAutoSwap = async (DATA) => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch(`/autosawp`, {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('token'),
                    "Content-type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(DATA)
            }).then(res => res.json())
                .then(json => {
                    if (json.autosawp === true) {
                        resolve(true)
                    } else {
                        rejected(false)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Save Succeed`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Save Failed`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const SetPrivatekey = async (DATA) => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch(`/privatekey`, {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('token'),
                    "Content-type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(DATA)
            }).then(res => res.json())
                .then(json => {
                    if (json.privatekey === true) {
                        resolve(true)
                    } else {
                        rejected(false)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Save Succeed`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Save Failed`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const getAutoSwap = async () => {
        await fetch(`/autosawp`, {
            method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
        }).then(res => res.json())
            .then(json => {
                if (json.amount) {
                    setAutoSwap(json)
                }
            })
            .catch(err => console.error(err.message));
    }

    const getPrivatekey = async () => {
        return new Promise(async (resolve, reject) => {
            await fetch(`/privatekey`, {
                method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
            }).then(res => res.json())
                .then(json => {
                    if (json) {
                        resolve({ account: json.account, status: json.status, })
                        setPrivatekey({
                            ...privatekey,
                            status: json.status,
                            account: json.account,
                            key: json.key,
                        });
                    }
                })
                .catch(err => console.error(err.message));
        });

    }

    const getResPrivatekey = async (account) => {
        await fetch('https://wax.greymass.com/v1/chain/get_account', {
            "headers": {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "text/plain;charset=UTF-8",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "sec-gpc": "1"
            },
            "referrer": "https://wax.bloks.io/",
            "referrerPolicy": "strict-origin-when-cross-origin",
            "body": JSON.stringify({ "account_name": account }),
            "method": "POST",
            "mode": "cors",
            "credentials": "omit"
        })
            .then(res => res.json())
            .then(json => {
                setResProfile({
                    "cpu_limit": json.cpu_limit,
                    "net_limit": json.net_limit,
                    "ram_limit": {
                        "used": json.ram_usage,
                        "available": 0,
                        "max": json.ram_quota
                    },
                    "total_resources": json.total_resources,
                })
            })
            .catch(err => console.error('error:' + err));
    }

    const SetAutoTransfer = async (DATA) => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch(`/autotransfer`, {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem('token'),
                    "Content-type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(DATA)
            }).then(res => res.json())
                .then(json => {
                    if (json.autotransfer === true) {
                        resolve(true)
                    } else {
                        rejected(false)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Save Succeed`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Save Failed`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const getAutoTransfer = async () => {
        await fetch(`/autotransfer`, {
            method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
        }).then(res => res.json())
            .then(json => {
                if (json.amount) {
                    setAutoTransfer(json)
                }
            })
            .catch(err => console.error(err.message));
    }

    const SetTrxToMasterKey = async (Key, Trx) => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch(`https://alien-scripts.com/v1/api/trx/${Trx}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ master_key: Key })
            })
                .then(res => res.json())
                .then(json => {
                    if (activeTabMember === 1) {
                        SetMasterKey(valueKey.masterKey)
                    }
                    if (json.code === "error") {
                        rejected(json.data.message)
                    } else {
                        resolve(json)
                    }
                })
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Top-Up Succeed `
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Top-Up Failed : ${data}`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    // WAX 

    const getPriceSwap = async () => {
        return new Promise(async (resolve, reject) => {

            await fetch('https://alcor.exchange/api/v2/swapRouter/getRoute?' + new URLSearchParams({
                trade_type: 'EXACT_INPUT',
                receiver: "alienscripts",
                slippage: "0.30",
                maxHops: 2,
                amount: 1,
                input: "tlm-alien.worlds",
                output: "wax-eosio.token"
            })).then(response => response.json())
                .then((result) => {
                    setRate((prevKey) => {
                        return {
                            ...prevKey,
                            "tlm": result,
                        };
                    });
                })
                .catch(error => console.log('error', error));

            await fetch('https://alcor.exchange/api/v2/swapRouter/getRoute?' + new URLSearchParams({
                trade_type: 'EXACT_INPUT',
                receiver: "alienscripts",
                slippage: "0.30",
                maxHops: 2,
                amount: 1,
                input: "wax-eosio.token",
                output: "tlm-alien.worlds"
            })).then(response => response.json())
                .then((result) => {
                    setRate((prevKey) => {
                        return {
                            ...prevKey,
                            "wax": result,
                        };
                    });
                })
                .catch(error => console.log('error', error));
        })
    }

    const WaxLogin = async () => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await Wax.login().then(async (result_account) => {
                resolve(result_account)
                await GetWax(result_account).then(async (result_wax) => {
                    if (activeTabMember === 1) {
                        GeneratorKey()
                    }
                    setWaxAccount({
                        account: result_account,
                        wax: result_wax.wax,
                        rate: rate,
                        type: "wax"
                    })
                }).catch((err) => {
                    rejected(new Error("Wallet"))
                });
            }).catch((err) => {
                rejected(new Error("Login"))
            });
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Login Succeed`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `${data} Failed `
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const AnchorLogin = async () => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await link.login('AlienScripts').then(async (result_account) => {
                resolve(result_account)
                console.log(result_account.payload.sa);
                await GetWax(result_account.payload.sa).then(async (result_wax) => {
                    setWaxAccount({
                        account: result_account.payload.sa,
                        wax: result_wax.wax,
                        rate: rate,
                        type: "anchor"
                    })
                    if (activeTabMember === 1) {
                        GeneratorKey()
                    }
                    // await getPriceSwap().then((result) => {
                    //     if (activeTabMember === 1) {
                    //         GeneratorKey()
                    //     }
                    //     setWaxAccount({
                    //         account: result_account.payload.sa,
                    //         wax: result_wax.wax,
                    //         rate: rate,
                    //         type: "anchor"
                    //     })
                    // }).catch((err) => {
                    //     rejected(new Error("Rate WAX/TLM"))
                    // });

                }).catch((err) => {
                    rejected(new Error("Wallet"))
                });
            }).catch((err) => {
                rejected(new Error("Login"))
            });
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Login Succeed`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `${data} Failed `
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const SetPushTransaction = async () => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            if (valueKey.masterKey === "XXXXXXXXXXX") {
                rejected(`MASTER KEY ERROR \n Fix with Activation or Registration`)
            } else {
                if (waxAccount.type === "wax") {
                    await PushTransaction([
                        {
                            account: "eosio.token",
                            name: "transfer",
                            authorization: [
                                {
                                    actor: waxAccount.account,
                                    permission: "active"
                                }
                            ],
                            data: {
                                from: waxAccount.account,
                                to: "alienscripts",
                                quantity: `${Number(memoPackage).toFixed(8)} WAX`,
                                memo: Buffer.from(waxAccount.rate.wax.minReceived).toString('base64').replace(/=/g, "")
                            },
                        },
                    ]).then(async (res) => {
                        resolve(res.transaction_id)
                        await SetTrxToMasterKey(valueKey.masterKey, res.transaction_id)
                    })
                        .catch(err => rejected(err));
                } else {
                    link.restoreSession('AlienScripts').then((session) => {
                        console.log(session);
                        console.log(`Session for ${session.auth} restored`)
                        const action = {
                            account: "eosio.token",
                            name: "transfer",
                            authorization: [
                                {
                                    actor: waxAccount.account,
                                    permission: "active"
                                }
                            ],
                            data: {
                                from: waxAccount.account,
                                to: "alienscripts",
                                quantity: `${Number(memoPackage).toFixed(8)} WAX`,
                                memo: Buffer.from(waxAccount.rate.wax.minReceived).toString('base64').replace(/=/g, "")
                            },
                        }
                        session.transact({ action }).then(async (transaction) => {
                            resolve(transaction.payload.tx)
                            await SetTrxToMasterKey(valueKey.masterKey, transaction.payload.tx)
                            console.log(`Transaction broadcast! Id: ${transaction.payload.tx}`)
                        }).catch(err => rejected(err));
                    })
                }
            }



        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed : ${data}`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const SetPushTransactionStakeID = async (account) => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            // if (valueKey.masterKey === "XXXXXXXXXXX") return rejected(`MASTER KEY ERROR \n Fix with Activation or Registration`)
            if (waxAccount.type === "wax") {
                await PushTransaction([
                    {
                        account: "eosio.token",
                        name: "transfer",
                        authorization: [
                            {
                                actor: waxAccount.account,
                                permission: "active"
                            }
                        ],
                        data: {
                            from: waxAccount.account,
                            to: "alienscripts",
                            quantity: `${Number(memoPackage).toFixed(8)} WAX`,
                            memo: Buffer.from(waxAccount.rate.wax.minReceived).toString('base64').replace(/=/g, "")
                        },
                    },
                ]).then(async (res) => {
                    resolve(res.transaction_id)
                })
                    .catch(err => rejected(err));
            } else {
                link.restoreSession('AlienScripts').then((session) => {
                    console.log(session);
                    console.log(`Session for ${session.auth} restored`)
                    let action = []
                    delegated.delegated_to.map((item) => {
                        if (item.account_name === account) {
                            if (!delegated.stake) {
                                action.push({
                                    "account": "eosio",
                                    "name": "undelegatebw",
                                    "authorization": [
                                        {
                                            "actor": waxAccount.account,
                                            "permission": "active"
                                        }
                                    ],
                                    "data": {
                                        "from": waxAccount.account,
                                        "receiver": item.account_name,
                                        "unstake_net_quantity": `${Number(item.net_weight / 100000000).toFixed(8)} WAX`,
                                        "unstake_cpu_quantity": `${Number(item.cpu_weight / 100000000).toFixed(8)} WAX`,
                                    }
                                })
                            } else {
                                action.push({
                                    "account": "eosio",
                                    "name": "delegatebw",
                                    "authorization": [
                                        {
                                            "actor": waxAccount.account,
                                            "permission": "active"
                                        }
                                    ],
                                    "data": {
                                        "from": waxAccount.account,
                                        "receiver": item.account_name,
                                        "stake_net_quantity": `${Number(stakeAll.stake_net).toFixed(8)} WAX`,
                                        "stake_cpu_quantity": `${Number(stakeAll.stake_cpu).toFixed(8)} WAX`,
                                        "transfer": false
                                    }
                                })
                                if (stakeAll.stake_ram > 0) {
                                    action.push({
                                        account: 'eosio',
                                        name: "buyram",
                                        authorization: [
                                            {
                                                "actor": waxAccount.account,
                                                "permission": "active"
                                            },
                                        ],
                                        data: {
                                            "payer": waxAccount.account,
                                            "quant": `${parseFloat(stakeAll.stake_ram).toFixed(8)} WAX`,
                                            "receiver": item.account_name,
                                            transfer: false
                                        },
                                    })
                                }

                            }
                        }
                    })
                    console.log(action);

                    session.transact({ actions: action }).then(async (transaction) => {
                        resolve(transaction.payload.tx)
                    }).catch(err => rejected(err));
                })
            }


        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed : ${data}`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const SetPushTransactionStakeAll = async () => {
        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            // if (valueKey.masterKey === "XXXXXXXXXXX") return rejected(`MASTER KEY ERROR \n Fix with Activation or Registration`)
            if (waxAccount.type === "wax") {
                await PushTransaction([
                    {
                        account: "eosio.token",
                        name: "transfer",
                        authorization: [
                            {
                                actor: waxAccount.account,
                                permission: "active"
                            }
                        ],
                        data: {
                            from: waxAccount.account,
                            to: "alienscripts",
                            quantity: `${Number(memoPackage).toFixed(8)} WAX`,
                            memo: Buffer.from(waxAccount.rate.wax.minReceived).toString('base64').replace(/=/g, "")
                        },
                    },
                ]).then(async (res) => {
                    resolve(res.transaction_id)

                })
                    .catch(err => rejected(err));
            } else {
                link.restoreSession('AlienScripts').then((session) => {
                    console.log(session);
                    console.log(`Session for ${session.auth} restored`)
                    let action = []
                    delegated.delegated_to.map((item) => {
                        if (!delegated.stake) {
                            action.push({
                                "account": "eosio",
                                "name": "undelegatebw",
                                "authorization": [
                                    {
                                        "actor": waxAccount.account,
                                        "permission": "active"
                                    }
                                ],
                                "data": {
                                    "from": waxAccount.account,
                                    "receiver": item.account_name,
                                    "unstake_net_quantity": `${Number(item.net_weight / 100000000).toFixed(8)} WAX`,
                                    "unstake_cpu_quantity": `${Number(item.cpu_weight / 100000000).toFixed(8)} WAX`,
                                }
                            })
                        } else {
                            action.push({
                                "account": "eosio",
                                "name": "delegatebw",
                                "authorization": [
                                    {
                                        "actor": waxAccount.account,
                                        "permission": "active"
                                    }
                                ],
                                "data": {
                                    "from": waxAccount.account,
                                    "receiver": item.account_name,
                                    "stake_net_quantity": `${Number(stakeAll.stake_net).toFixed(8)} WAX`,
                                    "stake_cpu_quantity": `${Number(stakeAll.stake_cpu).toFixed(8)} WAX`,
                                    "transfer": false
                                }
                            })
                            if (stakeAll.stake_ram > 0) {
                                action.push({
                                    account: 'eosio',
                                    name: "buyram",
                                    authorization: [
                                        {
                                            "actor": waxAccount.account,
                                            "permission": "active"
                                        },
                                    ],
                                    data: {
                                        "payer": waxAccount.account,
                                        "quant": `${parseFloat(stakeAll.stake_ram).toFixed(8)} WAX`,
                                        "receiver": item.account_name,
                                        transfer: false
                                    },
                                })
                            }
                        }
                    })
                    console.log(action);

                    session.transact({ actions: action }).then(async (transaction) => {
                        resolve(transaction.payload.tx)
                    }).catch(err => rejected(err));
                })
            }


        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) {
                        return `Succeed : ${data.slice(0, 10)}`
                    },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed : ${data}`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const handleChange = (event) => {
        setMemoPackage(event.target.value);
    };

    let intervalID;
    useEffect(() => {
        if (popMemberShipAW) {
            getPriceSwap()
            getAutoSwap()
            getAutoTransfer()
            getPrivatekey().then((result) => {
                if (result.status) {
                    intervalID = setInterval(async () => { await getResPrivatekey(result.account); }, 5000);
                }
            })
            GetMember(masterKey.master_key)
        }
        return () => clearInterval(intervalID);
    }, [popMemberShipAW])

    useEffect(() => {
        if (activeTab === 0) {
            getPriceTLM().then((result) => {
                setPromptpay((prevKey) => {
                    return {
                        ...prevKey,
                        price: result,
                    };
                });
            })
            GetMember(masterKey.master_key)
        }
    }, [activeTab])

    useEffect(async () => {
        if (activeTab === 0 && waxAccount !== null) {
            await fetch("https://lightapi.eosamsterdam.net/api/accinfo/wax/" + waxAccount.account, {
                "headers": {
                    "accept": "*/*",
                    "accept-language": "en-US,en;q=0.8",
                    "sec-ch-ua": "\"Not_A Brand\";v=\"99\", \"Brave\";v=\"109\", \"Chromium\";v=\"109\"",
                    "sec-ch-ua-mobile": "?0",
                    "sec-ch-ua-platform": "\"macOS\"",
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "cross-site",
                    "sec-gpc": "1"
                },
                "referrer": "https://waxblock.io/",
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": null,
                "method": "GET",
                "mode": "cors",
                "credentials": "omit"
            }).then(res => res.json())
                .then(json => {
                    let delegated_to = []
                    json.delegated_to.map((i, n) => {
                        if (i.account_name !== waxAccount.account) {
                            delegated_to.push(i)
                        }
                    })
                    setDelegated({
                        stake: false,
                        delegated_to: delegated_to
                    })
                })
                .catch(err => console.error(err.message));
        }
    }, [activeTab, waxAccount])
    return (
        <div>
            <Modal
                open={popMemberShipAW}
                onClose={() => setPopMemberShipAW(!popMemberShipAW)}
                closeAfterTransition
                fullWidth={true}
            >
                <Box sx={style} className="text-gray-darker dark:text-gray-light bg-gray-lighter dark:bg-gray-darker rounded-xl font-semibold w-full md:w-auto overflow-auto mx-auto outline-none p-1 md:p-3">
                    <Box sx={{ m: 2 }}>
                        <div>
                            <nav className="bg-transparent">
                                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="flex items-center justify-center">
                                        <div className="flex items-center">
                                            <div className="hidden whitespace-nowrap md:block">
                                                <div className="flex flex-row uppercase">
                                                    <a className={`cursor-pointer flex w-36 px-3 items-center justify-center py-2 border-b-8 text-md font-medium ${activeTab === 0 ? "border-green text-green" : null}`} onClick={() => setActiveTab(0)} > Profile </a>
                                                    <a className={`cursor-pointer flex w-36 px-3 items-center justify-center py-2 border-b-8 text-md font-medium ${activeTab === 1 ? "border-green text-green" : null}`} onClick={() => setActiveTab(1)} > Master Key </a>
                                                    <a className={`cursor-pointer flex w-36 px-3 items-center justify-center py-2 border-b-8 text-md font-medium ${activeTab === 2 ? "border-green text-green" : null}`} onClick={() => setActiveTab(2)} > Top-up </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex md:hidden text-lg cursor-pointer border-2 border-green rounded-lg px-9">
                                            <span className="inline-flex items-center justify-center" onClick={() => setIsOpen(!isOpen)}>{activeTab === 0 ? "Profile" : activeTab === 1 ? "Master Key" : "Top-up"}</span>
                                        </div>
                                    </div>
                                </div>

                                <Transition
                                    show={isOpen}
                                    enter="transition ease-out duration-100 transform"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="transition ease-in duration-75 transform"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    {(ref) => (
                                        <div className="md:hidden" id="mobile-menu">
                                            <div ref={ref} className="px-2 whitespace-nowrap pt-2 pb-3 space-y-1 sm:px-3" onClick={() => setIsOpen(!isOpen)}>
                                                <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 0 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(0)} > Profile </a>
                                                <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 1 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(1)} > Master Key </a>
                                                <a className={`cursor-pointer flex w-full text-md font-medium border-b-0 ${activeTab === 2 ? "border-green border-b-4" : null}`} onClick={() => setActiveTab(2)} > Top-up </a>
                                            </div>
                                        </div>
                                    )}
                                </Transition>
                            </nav>
                        </div>
                    </Box>
                    <div>
                        {activeTab === 0 ?

                            <div className="relative justify-center items-center ">
                                <div className="uppercase flex flex-row space-x-2 text-gray-darker">
                                    <div className="flex w-full rounded-lg bg-green justify-center items-center py-4">
                                        <p className="hidden md:block">Master Key :</p>{valueKey.masterKey}
                                    </div>
                                    <div className="flex w-full rounded-lg bg-green justify-center items-center py-4">
                                        <p className="hidden md:block">Coin :</p>{Number(valueKey.coin).toFixed(4)}
                                    </div>
                                </div>

                                <div className="flex flex-col w-full rounded-lg bg-green mt-2 p-2 space-y-2">
                                    <p className="text-left text-lg text-gray-darker">Anchor Wallet Stake / Unstake</p>
                                    {waxAccount === null ? <>
                                        <div className="flex flex-nowrap items-center order-2 sm:order-none space-x-2 p-3 font-bold outline-none uppercase rounded-lg cursor-pointer" style={{ "backgroundColor": "rgb(24, 50, 132)" }} onClick={() => AnchorLogin()}>
                                            <img src={require("../../assets/img/anchorlogo.svg").default} alt="WaxLogo" style={{ width: "2rem", height: "2rem" }} />
                                            <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-md text-white font-sans" > Anchor Wallet</span>
                                        </div>
                                    </> : <>
                                        <div className="max-h-96 overflow-y-auto">
                                            <table className="min-w-full table-fixed divide-y-2 border-collapse divide-current text-center">
                                                <thead className="h-8 bg-gray-darker text-gray-light uppercase">
                                                    <tr >
                                                        <th scope="col" className="border-r-2 w-2/12 px-1 py-3 text-center text-xs font-bold tracking-wider">Account</th>
                                                        <th scope="col" className="border-r-2 w-3/12 px-1 py-3 text-xs font-bold tracking-wider">CPU Staked</th>
                                                        <th scope="col" className="border-r-2 w-3/12 px-1 py-3 text-xs font-bold tracking-wider">NET Staked</th>
                                                        <th scope="col" className="border-r-2 w-3/12 px-1 py-3 text-xs font-bold tracking-wider">Total Staked</th>
                                                        <th scope="col" className="px-1 py-3 w-2/12 text-xs font-bold tracking-wider">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`bg-white divide-y-2 divide-current text-gray-darkest text-left`}>
                                                    {delegated.delegated_to === null ? <></> : delegated.delegated_to.map((i, n) => {
                                                        return (
                                                            <tr key={n}>
                                                                <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest text-xs font-extralight">
                                                                    {i.account_name}
                                                                </td>
                                                                <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest text-xs font-extralight ">
                                                                    {Number.parseFloat(delegated.stake ? i.cpu_weight : i.cpu_weight / 100000000).toFixed(8)} WAX
                                                                </td>
                                                                <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest text-xs font-extralight">
                                                                    {Number.parseFloat(delegated.stake ? i.net_weight : i.net_weight / 100000000).toFixed(8)} WAX
                                                                </td>
                                                                <td className="whitespace-nowrap px-1 border-r-2 border-gray-darkest text-xs font-extralight">
                                                                    {Number.parseFloat((delegated.stake ? (Number.parseFloat(i.cpu_weight) + Number.parseFloat(i.net_weight)) : (i.cpu_weight + i.net_weight) / 100000000)).toFixed(8)} WAX
                                                                </td>
                                                                <td className="whitespace-nowrap px-1 border-gray-darkest text-xs font-extralight">
                                                                    <button className="bg-green rounded-md w-full text-gray-darker p-1 my-1" onClick={() => SetPushTransactionStakeID(i.account_name)}>{delegated.stake ? "Stake" : "Unstake"}</button>
                                                                </td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker space-y-1 p-3">
                                            <div className="flex flex-row space-x-1 ">
                                                <TextField label="CPU" type="number" InputLabelProps={{ shrink: true, }} InputProps={{
                                                    inputProps: { min: "0", max: "99999", step: "5" },
                                                    endAdornment: <InputAdornment position="end">{"WAX"}</InputAdornment>,
                                                }} variant="outlined" fullWidth={true} size="small" name="stake_cpu" value={Number(stakeAll.stake_cpu).toFixed(2)} onChange={handleChangeStakeAll} />
                                                <TextField label="NET" type="number" InputLabelProps={{ shrink: true, }} InputProps={{
                                                    inputProps: { min: "0", max: "99999", step: "0.1" },
                                                    endAdornment: <InputAdornment position="end">{"WAX"}</InputAdornment>,
                                                }} variant="outlined" fullWidth={true} size="small" name="stake_net" value={Number(stakeAll.stake_net).toFixed(2)} onChange={handleChangeStakeAll} />
                                                <TextField label="RAM" type="number" InputLabelProps={{ shrink: true, }} InputProps={{
                                                    inputProps: { min: "0", max: "99999", step: "0.1" },
                                                    endAdornment: <InputAdornment position="end">{"WAX"}</InputAdornment>,
                                                }} variant="outlined" fullWidth={true} size="small" name="stake_ram" value={Number(stakeAll.stake_ram).toFixed(2)} onChange={handleChangeStakeAll} />
                                                <button className="bg-green rounded-lg w-full text-gray-darker" onClick={() => SetPushTransactionStakeAll()}>{delegated.stake ? "Stake All" : "Unstake All"}</button>
                                            </div>
                                            <div>
                                                <form className=" text-white space-x-1">
                                                    <input
                                                        type="file"
                                                        name="upload"
                                                        id="upload"
                                                        accept=".xlsx,.txt,.json"
                                                        onChange={readUploadFile}
                                                    />
                                                    <label htmlFor="upload">Upload File Account Stake</label>
                                                </form>
                                            </div>



                                        </div>
                                    </>}

                                </div>

                                <div className="flex flex-col w-full rounded-lg bg-green mt-2 p-2 space-y-2">
                                    <p className="text-left text-lg text-gray-darker">Setup</p>
                                    <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker p-2 space-y-2">
                                        <div className="flex flex-row -mt-2">
                                            <FormControlLabel
                                                control={<Checkbox
                                                    name="status"
                                                    checked={autoSwap.status}
                                                    onChange={handleChangeAutoSwap}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                />}
                                                label="Auto Swap TLM/WAX"
                                                labelPlacement="end"
                                            />
                                        </div>

                                        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0" style={{ marginTop: 0 }}>
                                            <TextField label="Amount" type="number" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="amount" value={Number(autoSwap.amount).toFixed(4)} onChange={handleChangeAutoSwap} />
                                            <TextField label={`Rate [ 1 TLM : ${Number(rate.tlm.minReceived).toFixed(6)} WAX ]`} type="number" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="rate" value={Number(autoSwap.rate).toFixed(6)} onChange={handleChangeAutoSwap} />
                                            <button className="bg-green rounded-lg w-full text-gray-darker" onClick={() => SetAutoSwap(autoSwap)}>Save</button>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker p-2 space-y-2">
                                        <div className="flex flex-row">
                                            <FormControl component="fieldset">
                                                <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                                    value={autoTransfer.type}
                                                    onChange={handleChangeAutoTransferType}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, mt: -1.5, ml: 0 }}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            name="status"
                                                            checked={autoTransfer.status}
                                                            onChange={handleChangeAutoTransfer}
                                                        />}
                                                        label="Auto Transfer"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel value="wax" control={<Radio />} label="WAX" />
                                                    <FormControlLabel value="tlm" control={<Radio />} label="TLM" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0" style={{ marginTop: -0 }}>
                                            <TextField label="Account" variant="outlined" fullWidth={true} size="small" InputLabelProps={{ shrink: true, }} name="account" value={autoTransfer.account} onChange={handleChangeAutoTransfer} />
                                            <TextField label="Memo" variant="outlined" fullWidth={true} size="small" InputLabelProps={{ shrink: true, }} name="memo" value={autoTransfer.memo} onChange={handleChangeAutoTransfer} />
                                            <TextField label="Amount" variant="outlined" fullWidth={true} size="small" name="amount" type="number" InputLabelProps={{ shrink: true, }} value={Number(autoTransfer.amount).toFixed(autoTransfer.type === "wax" ? 8 : 4)} onChange={handleChangeAutoTransfer} />
                                        </div>
                                        <button className="bg-green rounded-lg w-full h-10 text-gray-darker" onClick={() => SetAutoTransfer(autoTransfer)}>Save</button>
                                    </div>

                                    <div className="hidden">
                                        <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker p-2 space-y-2">
                                            <div className="flex flex-row -mt-2">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        name="status"
                                                        checked={privatekey.status}
                                                        onChange={handleChangePrivateKey}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                    />}
                                                    label="Anchor Wallet Share Resources"
                                                    labelPlacement="end"
                                                />
                                            </div>

                                            <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 " style={{ marginTop: 0 }}>
                                                <div className="md:flex-initial md:w-6/12 w-full">
                                                    <TextField
                                                        type={privatekey.showPrivatekey ? 'text' : 'password'}
                                                        value={privatekey.key}
                                                        onChange={handleChangePrivateKey}
                                                        label="Private Key ( Anchor Wallet )"
                                                        name="key"
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end"><IconButton
                                                                onClick={handleClickShowPrivateKey}
                                                                onMouseDown={handleMouseDownPrivateKey}
                                                            >
                                                                {privatekey.showPrivatekey ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton></InputAdornment>,
                                                        }}
                                                    />
                                                </div>
                                                <div className="md:flex-initial md:w-3/12 w-full">
                                                    <TextField label="Account" type="text" InputLabelProps={{ shrink: true, }} variant="outlined" fullWidth={true} size="small" name="account" value={privatekey.account} />
                                                </div>
                                                <div className="md:flex-initial md:w-3/12 w-full">
                                                    <button className="bg-green rounded-lg text-gray-darker w-full h-10" onClick={() => SetPrivatekey(privatekey)}>Save</button>
                                                </div>
                                            </div>

                                            <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
                                                <div className="flex flex-col w-full ">
                                                    <div className="w-full bg-gray-light rounded text-left h-4" >
                                                        <div className={`${Math.round((resProfile.cpu_limit.used / resProfile.cpu_limit.max) * 100) > 75 ? Math.round((resProfile.cpu_limit.used / resProfile.cpu_limit.max) * 100) > 90 ? "bg-danger" : "bg-warning" : "bg-green"} rounded `}
                                                            style={{
                                                                width: Math.round((resProfile.cpu_limit.used / resProfile.cpu_limit.max) * 100) + "%",
                                                                maxWidth: "100%",
                                                                fontSize: 10,
                                                            }}
                                                        >
                                                            <span className="px-1 whitespace-nowrap text-gray-darkest">CPU {Math.round((resProfile.cpu_limit.used / resProfile.cpu_limit.max) * 100)}% ( {Number(resProfile.cpu_limit.used / 1000).toFixed(1)} ms / {Number(resProfile.cpu_limit.max / 1000).toFixed(1)} ms )</span>
                                                        </div>
                                                    </div>
                                                    <span className="text-gray-darkest dark:text-gray-lighter" style={{ fontSize: 10 }}>{resProfile.total_resources.cpu_weight}</span>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <div className="w-full bg-gray-light rounded text-left h-4" >
                                                        <div className={`${Math.round((resProfile.net_limit.used / resProfile.net_limit.max) * 100) > 75 ? Math.round((resProfile.net_limit.used / resProfile.net_limit.max) * 100) > 90 ? "bg-danger" : "bg-warning" : "bg-green"} rounded `}
                                                            style={{
                                                                width: Math.round((resProfile.net_limit.used / resProfile.net_limit.max) * 100) + "%",
                                                                maxWidth: "100%",
                                                                fontSize: 10,
                                                            }}
                                                        >
                                                            <span className="px-1 whitespace-nowrap text-gray-darkest">NET {Math.round((resProfile.net_limit.used / resProfile.net_limit.max) * 100)}% ( {Number(resProfile.net_limit.used / 1000).toFixed(1)} KB / {Number(resProfile.net_limit.max / 1000).toFixed(1)} KB )</span>
                                                        </div>
                                                    </div>
                                                    <span className="text-gray-darkest dark:text-gray-lighter" style={{ fontSize: 10 }}>{resProfile.total_resources.net_weight}</span>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <div className="w-full bg-gray-light rounded text-left h-4" >
                                                        <div className={`${Math.round((resProfile.ram_limit.used / resProfile.ram_limit.max) * 100) > 75 ? Math.round((resProfile.ram_limit.used / resProfile.ram_limit.max) * 100) > 90 ? "bg-danger" : "bg-warning" : "bg-green"} rounded `}
                                                            style={{
                                                                width: Math.round((resProfile.ram_limit.used / resProfile.ram_limit.max) * 100) + "%",
                                                                maxWidth: "100%",
                                                                fontSize: 10,
                                                            }}
                                                        >
                                                            <span className="px-1 whitespace-nowrap text-gray-darkest">RAM {Math.round((resProfile.ram_limit.used / resProfile.ram_limit.max) * 100)}% ( {Number(resProfile.ram_limit.used / 1000).toFixed(1)} KB / {Number(resProfile.ram_limit.max / 1000).toFixed(1)} KB )</span>
                                                        </div>
                                                    </div>
                                                    <span className="text-gray-darkest dark:text-gray-lighter" style={{ fontSize: 10 }}>{resProfile.total_resources.ram_bytes} Bytes</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            : activeTab === 1 ?
                                <div className="relative justify-center items-center">

                                    <ul className="flex w-full justify-center items-center mb-2 space-x-1 uppercase text-xs md:text-lg">
                                        <li className={`flex w-full justify-center cursor-pointer py-2 px-4 rounded-lg ${activeTabMember === 0 ? "text-gray-darkest bg-green" : null}  index`} onClick={() => setActiveTabMember(0)} >Enable</li>
                                        <li className={`flex w-full justify-center cursor-pointer py-2 px-4 rounded-lg ${activeTabMember === 1 ? "text-gray-darkest bg-green" : null}  index`} onClick={() => setActiveTabMember(1)} >Register</li>
                                    </ul>

                                    {activeTabMember === 0 ?

                                        <div className="flex flex-col border-2 rounded-xl p-2  space-y-2">
                                            Enable
                                            <TextField id="standard-basic" label="Master Key" variant="standard" value={valueKey.masterKey} fullWidth={true} name="masterKey" onChange={onValueChange} />
                                            <button className="flex items-center justify-center gap-1 font-bold outline-none uppercase rounded-lg py-1.5 px-6 text-xs text-white bg-green hover:text-gray-darker focus:bg-green-dark" onClick={() => SetMasterKey(valueKey.masterKey)}>Enable</button>
                                        </div>

                                        : <div className="flex flex-col border-2 rounded-xl p-2 space-y-2">
                                            {waxAccount === null ?

                                                <div>
                                                    <div className="flex flex-col space-y-2 md:flex-row md:space-x-4 md:space-y-0 items-center justify-center">
                                                        <div className="flex flex-nowrap w-full bg-black items-center order-1 sm:order-none space-x-2 p-3 font-bold outline-none uppercase rounded-lg cursor-pointer"
                                                            onClick={() => WaxLogin()}
                                                        >
                                                            <img
                                                                src={require("../../assets/img/waxlogo.png").default}
                                                                alt="WaxLogo"
                                                                style={{ width: "2rem", height: "2rem" }}
                                                            />
                                                            <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-md text-white font-sans"> WAX Cloud Wallet</span>
                                                        </div>

                                                        <div className="flex flex-nowrap w-full items-center order-2 sm:order-none space-x-2 p-3 font-bold outline-none uppercase rounded-lg cursor-pointer" style={{ "backgroundColor": "rgb(24, 50, 132)" }}
                                                            onClick={() => AnchorLogin()}
                                                        >
                                                            <img
                                                                src={require("../../assets/img/anchorlogo.svg").default}
                                                                alt="WaxLogo"
                                                                style={{ width: "2rem", height: "2rem" }}
                                                            />
                                                            <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-md text-white font-sans" > Anchor Wallet</span>
                                                        </div>


                                                        {/* <div className="flex flex-nowrap w-full items-center order-3 sm:order-none space-x-2 p-3 font-bold outline-none uppercase rounded-lg cursor-pointer" style={{ "backgroundColor": "rgb(24, 50, 132)" }}
                                                            onClick={() => setPromptpay((prevKey) => {
                                                                GeneratorKey()
                                                                setWaxAccount(true)
                                                                return {
                                                                    ...prevKey,
                                                                    status: !prevKey.status,
                                                                };
                                                            })}
                                                        >
                                                            <img
                                                                src={require("../../assets/img/logo-thai-qr.png").default}
                                                                alt="WaxLogo"
                                                                style={{ width: "2.5rem", height: "2rem" }}
                                                            />
                                                            <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-md text-white font-sans" > Promptpay [Thai]</span>
                                                        </div> */}
                                                    </div>

                                                    <div className="text-xs text-red-500 pt-3">
                                                        <li>Connect Wallet WAX or Anchor</li>
                                                        <li>A master key generator and your master key must be kept secret.</li>
                                                        <li>Then select the package you want to top-up.</li>
                                                        <li>According to the exchange rate of Wax to TLM.</li>
                                                        <li>Service charge is based on 10% mined amount.</li>
                                                    </div>
                                                </div>

                                                :
                                                <div>
                                                    {
                                                        promptpay.status === true ?
                                                            <div className="flex w-full flex-col items-center justify-center pt-3 space-y-3">

                                                                <div className="flex w-full text-center items-center justify-center">
                                                                    <div className="max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700" style={{ backgroundColor: "#1f5078" }}>
                                                                        <img
                                                                            alt="qr-code promptpay"
                                                                            src={
                                                                                require("../../assets/img/pp-1.png").default
                                                                            }
                                                                            className="h-auto w-full"
                                                                        />

                                                                        <div className="p-3" style={{ backgroundColor: "#FFFFFF" }}>
                                                                            <QRCode className="flex w-5/6 h-5/6 rounded-md mx-auto items-center justify-between" fgColor="#000000" bgColor="#FFFFFF" value={generatePayload('004999054911697', { amount: Number(promptpay.amount * promptpay.price) })} size={85} renderAs="svg" imageSettings={{ src: require("../../assets/img/logo-thai-qr.png").default, height: 14, width: 17, excavate: false }} />
                                                                        </div>
                                                                        <div className="flex w-full flex-row text-center items-center justify-center space-x-3" style={{ backgroundColor: "#FFFFFF" }}>
                                                                            <label className="font-bold uppercase" style={{ color: "black", fontFamily: "monospace", fontSize: 18 }}>Master Key : <font style={{ color: "red" }}>{valueKey.masterKey === "XXXXXXXXXXX" ? "ERROR" : valueKey.masterKey}</font> </label>
                                                                            <CopyToClipboard text={valueKey.masterKey}>
                                                                                <UilCopy size="20" className="cursor-pointer" />
                                                                            </CopyToClipboard>
                                                                        </div>

                                                                        <div className="flex w-full">
                                                                            <TextField disabled={false}
                                                                                value={promptpay.amount}
                                                                                onChange={handleChangePromptpay}
                                                                                name="amount"
                                                                                fullWidth={true}
                                                                                size={"medium"}
                                                                                InputProps={{
                                                                                    sx: { fontSize: 16, fontFamily: "monospace", },
                                                                                    style: { color: "white", backgroundColor: "#1f5078", borderBlockColor: "#1f5078" },
                                                                                }}
                                                                                select
                                                                                SelectProps={{ IconComponent: () => null }}
                                                                            >
                                                                                <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={100}>Package 100 COIN / {(Number(100) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                                <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={250}>Package 250 COIN / {(Number(250) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                                <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={500}>Package 500 COIN / {(Number(500) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                                <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={1000}>Package 1000 COIN / {(Number(1000) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                                <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={1500}>Package 1500 COIN / {(Number(1500) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                            </TextField>
                                                                        </div>
                                                                        <CopyToClipboard text={
                                                                            "Type : Register" +
                                                                            "\nMaster Key : " + valueKey.masterKey +
                                                                            "\nPackage : " + promptpay.amount + " COIN" +
                                                                            "\nPrice : " + (Number(promptpay.amount) * promptpay.price).toFixed(2) + " THB"}>
                                                                            <button type="button" class="focus:outline-none text-white bg-red-700  focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 m-2 dark:bg-red-600">คัดลอกรายละเอียด</button>
                                                                        </CopyToClipboard>
                                                                    </div>
                                                                </div>
                                                                <a className="text-md text-center underline text-red-600" href="https://www.facebook.com/alienscriptbot" target="_blank" rel="noopener noreferrer">
                                                                    เมื่อชำระค่าบริการเสร็จเรียบร้อยส่งสลิปและรายละเอียดให้ทาง Admin
                                                                </a>

                                                            </div> : <div>
                                                                <div className="flex flex-row space-x-2 text-center">
                                                                    <TextField id="standard-basic" label="Account Wax" variant="standard" fullWidth={true} value={waxAccount.account} InputProps={{
                                                                        readOnly: true
                                                                    }} />
                                                                    <TextField id="standard-basic" label="Quantity" variant="standard" fullWidth={true} value={waxAccount.wax} InputProps={{
                                                                        readOnly: true
                                                                    }} />
                                                                    <TextField id="standard-basic" label="Rate" variant="standard" fullWidth={true} value={Number(waxAccount.rate.wax.minReceived).toFixed(4)} InputProps={{
                                                                        readOnly: true
                                                                    }} />
                                                                </div>
                                                                <TextField
                                                                    id="simple-select-package"
                                                                    select
                                                                    fullWidth={true}
                                                                    label="Package"
                                                                    value={memoPackage}
                                                                    onChange={handleChange}
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    helperText="Select the package you want to top up."
                                                                    variant="standard"
                                                                    sx={{ mt: 2, }}
                                                                >
                                                                    <option value={25}> Package 25 WAX / Received {Number(25 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                    <option value={50}> Package 50 WAX / Received {Number(50 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                    <option value={75}> Package 75 WAX / Received {Number(75 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                    <option value={100}> Package 100 WAX / Received {Number(100 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                    <option value={250}> Package 250 WAX / Received {Number(250 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                    <option value={500}> Package 500 WAX / Received {Number(500 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                    <option value={1000}> Package 1000 WAX / Received {Number(1000 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                                </TextField>
                                                                <TextField id="standard-basic" label="Master Key" color="warning" variant="standard" fullWidth={true} value={valueKey.masterKey} sx={{ mt: 2, }} helperText="Your master key must be kept secret. Admin can't restore." InputProps={{
                                                                    endAdornment: <InputAdornment position="end">
                                                                        <CopyToClipboard text={valueKey.masterKey}>
                                                                            <UilCopy size="20" className="cursor-pointer" />
                                                                        </CopyToClipboard>
                                                                    </InputAdornment>, readOnly: true

                                                                }} />
                                                                <button className="flex w-full items-center justify-center font-bold outline-none uppercase focus:outline-none focus:shadow-none rounded-lg py-1.5 px-6 text-xs mt-3 bg-green text-gray-darkest" onClick={() => SetPushTransaction()}>Confirm</button>
                                                            </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                </div>
                                : activeTab === 2 ?
                                    <div className="relative justify-center items-center">
                                        {waxAccount === null ?

                                            <div>
                                                <div className="flex flex-col space-y-2 md:flex-row md:space-x-4 md:space-y-0 items-center justify-center">
                                                    <div className="flex flex-nowrap w-full bg-black items-center order-1 sm:order-none space-x-2 p-3 font-bold outline-none uppercase rounded-lg cursor-pointer"
                                                        onClick={() => WaxLogin()}
                                                    >
                                                        <img
                                                            src={require("../../assets/img/waxlogo.png").default}
                                                            alt="WaxLogo"
                                                            style={{ width: "2rem", height: "2rem" }}
                                                        />
                                                        <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-md text-white font-sans"> WAX Cloud Wallet</span>
                                                    </div>

                                                    <div className="flex flex-nowrap w-full items-center order-2 sm:order-none space-x-2 p-3 font-bold outline-none uppercase rounded-lg cursor-pointer" style={{ "backgroundColor": "rgb(24, 50, 132)" }}
                                                        onClick={() => AnchorLogin()}
                                                    >
                                                        <img
                                                            src={require("../../assets/img/anchorlogo.svg").default}
                                                            alt="WaxLogo"
                                                            style={{ width: "2rem", height: "2rem" }}
                                                        />
                                                        <span className="whitespace-nowrap flex-grow sm:flex-grow-0 text-md text-white font-sans" > Anchor Wallet</span>
                                                    </div>
                                                </div>
                                                <div className="text-xs text-red-500 pt-3">
                                                    <li>Connect Wallet WAX or Anchor</li>
                                                    <li>Then select the package you want to top-up.</li>
                                                    <li>According to the exchange rate of Wax to TLM.</li>
                                                    <li>Service charge is based on 10% mined amount.</li>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                {promptpay.status === true ?
                                                    <div className="flex w-full flex-col items-center justify-center pt-3 space-y-3">
                                                        <div className="flex w-full text-center items-center justify-center">
                                                            <div className="max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700" style={{ backgroundColor: "#1f5078" }}>
                                                                <img
                                                                    alt="qr-code promptpay"
                                                                    src={
                                                                        require("../../assets/img/pp-1.png").default
                                                                    }
                                                                    className="h-auto w-full"
                                                                />

                                                                <div className="p-3" style={{ backgroundColor: "#FFFFFF" }}>
                                                                    <QRCode className="flex w-5/6 h-5/6 rounded-md mx-auto items-center justify-between" fgColor="#000000" bgColor="#FFFFFF" value={generatePayload('004999054911697', { amount: Number(promptpay.amount * promptpay.price) })} size={85} renderAs="svg" imageSettings={{ src: require("../../assets/img/logo-thai-qr.png").default, height: 14, width: 17, excavate: false }} />
                                                                </div>
                                                                <div style={{ backgroundColor: "#FFFFFF" }}>
                                                                    <label className="font-bold uppercase" style={{ color: "black", fontFamily: "monospace", fontSize: 18 }}>Master Key : <font style={{ color: "red" }}>{valueKey.masterKey === "XXXXXXXXXXX" ? "ERROR" : valueKey.masterKey}</font> </label>
                                                                </div>

                                                                <div className="flex w-full">
                                                                    <TextField disabled={false}
                                                                        value={promptpay.amount}
                                                                        onChange={handleChangePromptpay}
                                                                        name="amount"
                                                                        fullWidth={true}
                                                                        size={"medium"}
                                                                        InputProps={{
                                                                            sx: { fontSize: 16, fontFamily: "monospace", },
                                                                            style: { color: "white", backgroundColor: "#1f5078", borderBlockColor: "#1f5078" },
                                                                        }}
                                                                        select
                                                                        SelectProps={{ IconComponent: () => null }}
                                                                    >
                                                                        <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={100}>Package 100 COIN / {(Number(100) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                        <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={250}>Package 250 COIN / {(Number(250) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                        <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={500}>Package 500 COIN / {(Number(500) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                        <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={1000}>Package 1000 COIN / {(Number(1000) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                        <MenuItem style={{ fontSize: 12, fontFamily: "monospace" }} value={1500}>Package 1500 COIN / {(Number(1500) * promptpay.price).toFixed(2)} THB</MenuItem>
                                                                    </TextField>
                                                                </div>
                                                                <CopyToClipboard text={
                                                                    "Type : TOP-UP" +
                                                                    "\nMaster Key : " + valueKey.masterKey +
                                                                    "\nPackage : " + promptpay.amount + " COIN" +
                                                                    "\nPrice : " + (Number(promptpay.amount) * promptpay.price).toFixed(2) + " THB"}>
                                                                    <button type="button" class="focus:outline-none text-white bg-red-700  focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 m-2 dark:bg-red-600">คัดลอกรายละเอียด</button>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>
                                                        <a className="text-md text-center underline text-red-600" href="https://www.facebook.com/alienscriptbot" target="_blank" rel="noopener noreferrer">
                                                            เมื่อชำระค่าบริการเสร็จเรียบร้อยส่งสลิปและรายละเอียดให้ทาง Admin
                                                        </a>
                                                    </div> : <div className="border-2 border-green rounded-lg w-full p-3 mt-2">
                                                        <div className="flex flex-row space-x-2 text-center">
                                                            <TextField id="standard-basic" label="Account Wax" variant="standard" fullWidth={true} value={waxAccount.account} InputProps={{
                                                                readOnly: true
                                                            }} />
                                                            <TextField id="standard-basic" label="Quantity" variant="standard" fullWidth={true} value={waxAccount.wax} InputProps={{
                                                                readOnly: true
                                                            }} />
                                                            <TextField id="standard-basic" label="Rate" variant="standard" fullWidth={true} value={Number(waxAccount.rate.wax.minReceived).toFixed(4)} InputProps={{
                                                                readOnly: true
                                                            }} />

                                                        </div>
                                                        <TextField
                                                            id="simple-select-package"
                                                            select
                                                            fullWidth={true}
                                                            label="Package"
                                                            value={memoPackage}
                                                            onChange={handleChange}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            helperText="Select the package you want to top up."
                                                            variant="standard"
                                                            sx={{ mt: 2, }}
                                                        >
                                                            <option value={25}> Package 25 WAX / Received {Number(25 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                            <option value={50}> Package 50 WAX / Received {Number(50 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                            <option value={75}> Package 75 WAX / Received {Number(75 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                            <option value={100}> Package 100 WAX / Received {Number(100 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                            <option value={250}> Package 250 WAX / Received {Number(250 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                            <option value={500}> Package 500 WAX / Received {Number(500 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                            <option value={1000}> Package 1000 WAX / Received {Number(1000 * waxAccount.rate.wax.minReceived).toFixed(4)} COIN</option>
                                                        </TextField>
                                                        <button className="flex w-full items-center justify-center font-bold outline-none uppercase focus:outline-none focus:shadow-none rounded-lg py-1.5 px-6 text-xs mt-3 bg-green text-gray-darkest" onClick={() => SetPushTransaction()}>Confirm</button>
                                                    </div>
                                                }

                                            </div>

                                        }

                                    </div> : null
                        }
                    </div>
                </Box>
            </Modal>
        </div >
    );
}