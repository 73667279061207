/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { ProFileContext } from "../App";
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import URL_API from "../components/models/Constants";

const fetch = require("node-fetch");

function Login({ component: Component, ...rest }) {

  const { setLogin } = useContext(ProFileContext);

  const [activeTab, setActiveTab] = useState(0)

  const [info, setInfo] = useState({
    email: "",
    password: "",
    confirm: "",
  });

  const onValueChange = (event) => {
    const { name, value } = event.target;
    setInfo((prevInfo) => {
      return {
        ...prevInfo,
        [name]: value,
      };
    });
  };

  const SubmitLogin = async (data) => {

    if (!(data.email && data.password)) return;

    const options = {
      method: "POST",
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
        "Content-type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: new Buffer.from(data.password).toString('base64'),
      }),
    };

    await fetch("/login", options)
      .then(async (res) => {
        if (res.status === 200) {
          const jsonRes = await res.json()
          localStorage.setItem('token', jsonRes.token);
          toast.success("Login Succeed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" });
          setLogin(true)
        } else {
          localStorage.removeItem('token');
          toast.error("Login Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" });
          setLogin(false)
        }
      })
      .catch(err => {
        console.log("login Failed");
      });
  };

  const SubmitRegister = async (data) => {

    if (data.password !== data.confirm && activeTab === 1) {
      toast.error('Password does not match', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" });
      return
    }

    if (!(data.email && data.password)) return;

    const options = {
      method: "POST",
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
        "Content-type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: new Buffer.from(data.password).toString('base64'),
      }),
    };

    await fetch("/register", options)
      .then(async (res) => {
        if (res.status === 200) {
          const jsonRes = await res.json()
          localStorage.setItem('token', jsonRes.token);
          toast.success("Register Succeed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" });
        } else {
          localStorage.removeItem('token');
          toast.error("Register Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" });
        }
      })
      .catch(err => {
        console.log("Register Failed");
      });
  };

  useEffect(() => {

    const options = {
      method: "GET",
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('token'),
      },
    };

     fetch("/auth", options)
      .then(async (res) => {
        if (res.status === 200) {
          const jsonRes = await res.json()
          localStorage.setItem('token', jsonRes.token);
          setLogin(true)
        } else {
          setLogin(false)
          localStorage.removeItem('token');
          console.log(res);
        }
      })
      .catch(err => {
        console.log("login Failed");
      });
  }, [])

  return (
    <div className="flex items-center justify-center h-screen bg-gray-darkest">
      <div className="bg-gray-darkest flex-col max-w-sm border-4 border-green rounded-2xl px-4 py-5 m-2">
        <div className="flex justify-center items-center mt-4">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="130.000000pt"
            height="130.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#8dc53f"
              stroke="none"
            >
              <path
                d="M858 5109 c-145 -21 -302 -83 -429 -170 -219 -148 -381 -413 -418
                    -683 -15 -108 -15 -3284 0 -3392 58 -420 378 -759 799 -846 96 -19 3404 -19
                    3500 0 421 87 741 426 799 846 15 108 15 3284 0 3392 -37 270 -199 535 -418
                    683 -89 60 -231 126 -331 153 l-85 23 -1680 1 c-924 1 -1706 -2 -1737 -7z
                    m1997 -220 c965 -121 1638 -523 1945 -1162 83 -172 136 -349 160 -530 18 -138
                    13 -193 -29 -343 -103 -362 -234 -673 -409 -976 l-57 -98 -72 0 c-40 0 -73 1
                    -73 3 0 2 30 53 66 113 173 287 316 621 414 969 42 146 43 153 37 255 -19 318
                    -166 661 -396 927 -285 328 -745 567 -1316 682 -348 70 -636 84 -885 42 -456
                    -76 -850 -236 -1160 -470 -107 -82 -309 -283 -381 -381 -166 -226 -278 -464
                    -354 -752 l-35 -135 41 -139 c66 -228 181 -517 292 -739 42 -84 165 -308 193
                    -350 l16 -25 -74 0 -75 0 -60 108 c-186 329 -348 716 -433 1037 l-30 111 29
                    119 c103 421 296 770 577 1047 130 128 204 188 344 280 295 193 665 332 1060
                    397 269 45 374 46 665 10z m-1795 -1460 c647 -89 1099 -386 1289 -846 52 -127
                    82 -265 88 -406 l6 -128 -54 6 c-414 48 -695 121 -966 250 -406 194 -640 480
                    -694 845 -12 82 -7 278 7 301 8 14 117 6 324 -22z m3404 -6 c13 -79 6 -257
                    -14 -345 -99 -450 -503 -773 -1172 -937 -119 -29 -364 -71 -505 -86 l-63 -7 0
                    74 c0 272 119 577 308 795 193 221 517 392 902 476 141 31 363 61 491 65 46 2
                    46 2 53 -35z m-3514 -1858 l0 -115 -250 0 -250 0 0 -54 0 -55 220 -3 220 -3
                    27 -28 28 -27 3 -208 c3 -231 -1 -246 -58 -274 -31 -16 -71 -18 -377 -18
                    l-343 0 0 110 0 110 248 2 247 3 0 55 0 55 -217 5 -218 5 -27 28 -28 27 0 219
                    c0 202 2 221 19 240 11 12 33 25 50 30 17 5 183 10 369 10 l337 1 0 -115z
                    m920 0 l0 -115 -250 0 -250 0 2 -222 3 -223 248 -3 247 -2 0 -110 0 -110 -342
                    0 c-307 0 -347 2 -378 18 -60 30 -60 29 -58 443 3 356 4 378 22 398 11 12 33
                    25 50 30 17 5 183 10 369 10 l337 1 0 -115z m841 105 c72 -20 74 -27 77 -265
                    l3 -213 -30 -34 -29 -33 -219 -5 -218 -5 -3 -167 -2 -168 -140 0 -140 0 0 280
                    0 280 250 0 250 0 0 55 0 55 -250 0 -250 0 0 115 0 115 333 0 c190 0 347 -4
                    368 -10z m499 -440 l0 -450 -140 0 -140 0 0 450 0 450 140 0 140 0 0 -450z
                    m841 440 c72 -20 74 -27 77 -265 l3 -213 -30 -34 -29 -33 -219 -5 -218 -5 -3
                    -167 -2 -168 -140 0 -140 0 0 280 0 280 250 0 250 0 0 55 0 55 -250 0 -250 0
                    0 115 0 115 333 0 c190 0 347 -4 368 -10z m929 -105 l0 -115 -105 0 -105 0 0
                    -335 0 -335 -145 0 -145 0 0 335 0 335 -105 0 -105 0 0 115 0 115 355 0 355 0
                    0 -115z m-2258 -677 l67 -108 -142 0 -142 0 -48 78 c-26 42 -57 92 -68 110
                    l-21 33 143 -3 143 -3 68 -107z m-735 -297 c135 -90 305 -187 439 -250 l92
                    -43 68 27 c149 59 435 212 576 307 l58 39 102 -3 103 -3 -65 -45 c-263 -183
                    -541 -334 -782 -424 l-65 -25 -116 53 c-210 97 -444 235 -629 373 -49 36 -88
                    67 -88 69 0 2 42 4 94 4 l94 0 119 -79z"
              />
            </g>
          </svg>
        </div>
        <h1 className="text-center text-2xl text-white font-semibold py-3 uppercase">Alien-Script Cloud</h1>
        <div className="bg-gray-lightest dark:bg-gray-dark rounded-2xl p-3 relative justify-center items-center">
          <ul className="flex w-full justify-center items-center mb-2 space-x-1 uppercase">
            <li className={`flex w-full justify-center cursor-pointer py-2 px-4 rounded-lg font-semibold ${activeTab === 0 ? "bg-green" : null}`} onClick={() => setActiveTab(0)} >Login</li>
            <li className={`flex w-full justify-center cursor-pointer py-2 px-4 rounded-lg font-semibold ${activeTab === 1 ? "bg-green" : null}`} onClick={() => setActiveTab(1)} >Register</li>
          </ul>
          {activeTab === 0
            ?
            <div className="m-2 space-y-3">
              <TextField label="E-Mail" type="email" variant="standard" fullWidth={true} required name="email" value={info.email} onChange={onValueChange} />
              <TextField label="Password" variant="standard" type="password" autoComplete="current-password" fullWidth={true} required name="password" value={info.password} onChange={onValueChange} />
              <button className="bg-green rounded-lg py-2 font-semibold w-full" onClick={async () => { await SubmitLogin(info); }} >Sign In</button>
            </div>
            :
            <div className="m-2 space-y-3">
              <TextField label="E-Mail" type="email" variant="standard" fullWidth={true} required name="email" value={info.email} onChange={onValueChange} />
              <TextField label="Password" variant="standard" type="password" autoComplete="current-password" fullWidth={true} required name="password" value={info.password} onChange={onValueChange} />
              <TextField label="Confirm Password" variant="standard" type="password" autoComplete="current-password" fullWidth={true} required name="confirm" value={info.confirm} onChange={onValueChange} />
              <button className="bg-green rounded-lg py-2 font-semibold w-full" onClick={async () => { await SubmitRegister(info); }} >Sign Up</button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Login;
