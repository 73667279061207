/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import React, { useContext, useEffect, useState } from "react";
import { Box, Modal, Backdrop, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { ModalContext } from "../content/TableProfile";

// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';


import { toast } from "react-toastify";

const fetch = require('node-fetch');

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #8DC53F',
    maxHeight: '95%',
    maxWidth: '95%',
};

const worldlist = ["veles.world", "kavian.world", "eyeke.world", "naron.world", "neri.world", "magor.world",]

export default function MiningToolsModal() {
    const { showModalMiningTools, setShowModalMiningTools } = useContext(ModalContext);

    const [workingTime, setWorkingTime] = useState({
        "0": true,
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "6": true,
        "7": true,
        "8": true,
        "9": true,
        "10": true,
        "11": true,
        "12": true,
        "13": true,
        "14": true,
        "15": true,
        "16": true,
        "17": true,
        "18": true,
        "19": true,
        "20": true,
        "21": true,
        "22": true,
        "23": true,
    })

    const [pools, setPools] = useState({
        "eyeke.world": [
            {
                "key": "Abundant",
                "value": "0.2715 TLM"
            },
            {
                "key": "Common",
                "value": "0.3460 TLM"
            },
            {
                "key": "Epic",
                "value": "2.2541 TLM"
            },
            {
                "key": "Legendary",
                "value": "8.1592 TLM"
            },
            {
                "key": "Mythical",
                "value": "56.6217 TLM"
            },
            {
                "key": "Rare",
                "value": "4.1550 TLM"
            }
        ],
        "kavian.world": [
            {
                "key": "Abundant",
                "value": "0.1615 TLM"
            },
            {
                "key": "Common",
                "value": "0.2345 TLM"
            },
            {
                "key": "Epic",
                "value": "2.8681 TLM"
            },
            {
                "key": "Legendary",
                "value": "8.7084 TLM"
            },
            {
                "key": "Mythical",
                "value": "51.0050 TLM"
            },
            {
                "key": "Rare",
                "value": "3.4249 TLM"
            }
        ],
        "veles.world": [
            {
                "key": "Abundant",
                "value": "0.3970 TLM"
            },
            {
                "key": "Common",
                "value": "0.1871 TLM"
            },
            {
                "key": "Epic",
                "value": "2.3913 TLM"
            },
            {
                "key": "Legendary",
                "value": "8.1882 TLM"
            },
            {
                "key": "Mythical",
                "value": "46.3354 TLM"
            },
            {
                "key": "Rare",
                "value": "4.4103 TLM"
            }
        ],
        "naron.world": [
            {
                "key": "Abundant",
                "value": "0.2281 TLM"
            },
            {
                "key": "Common",
                "value": "0.3024 TLM"
            },
            {
                "key": "Epic",
                "value": "2.5853 TLM"
            },
            {
                "key": "Legendary",
                "value": "2.5870 TLM"
            },
            {
                "key": "Mythical",
                "value": "53.7807 TLM"
            },
            {
                "key": "Rare",
                "value": "3.4549 TLM"
            }
        ],
        "magor.world": [
            {
                "key": "Abundant",
                "value": "0.6273 TLM"
            },
            {
                "key": "Common",
                "value": "0.3974 TLM"
            },
            {
                "key": "Epic",
                "value": "1.7316 TLM"
            },
            {
                "key": "Legendary",
                "value": "5.4122 TLM"
            },
            {
                "key": "Mythical",
                "value": "49.8145 TLM"
            },
            {
                "key": "Rare",
                "value": "4.1177 TLM"
            }
        ],
        "neri.world": [
            {
                "key": "Abundant",
                "value": "0.0870 TLM"
            },
            {
                "key": "Common",
                "value": "0.2010 TLM"
            },
            {
                "key": "Epic",
                "value": "1.9303 TLM"
            },
            {
                "key": "Legendary",
                "value": "7.4958 TLM"
            },
            {
                "key": "Mythical",
                "value": "37.1432 TLM"
            },
            {
                "key": "Rare",
                "value": "3.1391 TLM"
            }
        ]
    })

    const getResPools = async () => {
        await Promise.all(worldlist.map(async (world) => {
            return await fetch('https://wax.pink.gg/v1/chain/get_table_rows', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', referer: 'https://play.alienworlds.io/' },
                body: JSON.stringify({
                    "reverse": true,
                    "json": true,
                    "upper_bound": "",
                    "lower_bound": "",
                    "key_type": "i64",
                    "index_position": 1,
                    "scope": world,
                    "code": "m.federation",
                    "table": "pools",
                    "limit": 1000,
                    "show_payer": false,
                    "table_key": ""
                })
            })
                .then(res => res.json())
                .then(json => setPools((prev) => {
                    return ({
                        ...prev,
                        [world]: json.rows[0].pool_buckets
                    });
                }))
                .catch(err => console.error('error:' + err));
        }))

    }

    const handleChangeWorkingTime = (event) => {

        setWorkingTime((prevTime) => {
            return {
                ...prevTime,
                [event.target.name]: event.target.checked
            };
        });
    };

    const handleChange = (event) => {

        const { name, value } = event.target;
        setShowModalMiningTools((prevTime) => {
            return {
                ...prevTime,
                [name]: value
            };
        });
    };

    const handleChangeMineBucket = (event) => {
        const { name, value } = event.target;
        setShowModalMiningTools((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
                "status": event.target.checked
            };
        });
    };

    const handleChangeMineDelay = (event) => {
        const { name, value } = event.target;
        setShowModalMiningTools((prevKey) => {
            return {
                ...prevKey,
                [name]: value,
                "statusDelay": event.target.checked
            };
        });
    };

    const PushTime = async (all = false) => {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                setAll: all,
                account: showModalMiningTools.wax,
                actions: workingTime
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/working", options)
                .then(res => res.json())
                .then(json => resolve(json))
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) { return `Succeed Setup` },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed Setup`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushMineBucket = async (all = false) => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                setAll: all,
                account: showModalMiningTools.wax,
                actions: {
                    mine_bucket: {
                        status: showModalMiningTools.status,
                        tlm: showModalMiningTools.mine_bucket,
                        rarity: showModalMiningTools.mine_rarity,
                        time_out: showModalMiningTools.time_out
                    },
                },
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/mine_bucket", options)
                .then(res => res.json())
                .then(json => resolve(json))
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) { return `Succeed Setup` },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed Setup`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    const PushManualDelayMine = async (all = false) => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                setAll: all,
                account: showModalMiningTools.wax,
                actions: {
                    mine_delay: {
                        status: showModalMiningTools.statusDelay,
                        time: showModalMiningTools.timeDelay
                    },
                },
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/mine_delay", options)
                .then(res => res.json())
                .then(json => resolve(json))
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) { return `Succeed Setup` },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed Setup`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }


    const getMineBucket = async (id) => {
        await fetch(`/mine_bucket/${id}`, {
            method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
        }).then(res => res.json())
            .then(json => {
                if (json.mine_bucket) {
                    setShowModalMiningTools((prevTime) => {
                        return {
                            ...prevTime,
                            mine_bucket: json.mine_bucket.tlm,
                            mine_rarity: json.mine_bucket.rarity,
                            status: json.mine_bucket.status,
                            time_out: json.mine_bucket.time_out

                        };
                    });
                }
            })
            .catch(err => console.error(err.message));
    }

    const getMineDelay = async (id) => {
        await fetch(`/mine_delay/${id}`, {
            method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
        }).then(res => res.json())
            .then(json => {
                if (json.mine_delay) {
                    setShowModalMiningTools((prevTime) => {
                        return {
                            ...prevTime,
                            statusDelay: json.mine_delay.status,
                            timeDelay: json.mine_delay.time
                        };
                    });
                }
            })
            .catch(err => console.error(err.message));
    }

    const getWorking = async (id) => {
        await fetch(`/working/${id}`, {
            method: 'GET', headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), },
        }).then(res => res.json())
            .then(json => {
                if (json.working) {
                    // {start: 0, stop: 0, strStart: 0, strStop: 0}
                    if (json.working.start > -1) {
                        console.log("working old data !!!")
                    } else {
                        setWorkingTime(json.working);
                    }

                }
            })
            .catch(err => console.error(err.message));
    }
    let intervalID;
    useEffect(() => {
        if (showModalMiningTools.show) {
            getMineBucket(showModalMiningTools.wax)
            getWorking(showModalMiningTools.wax)
            getMineDelay(showModalMiningTools.wax)
            intervalID = setInterval(async () => { await getResPools(); }, 2500);
        }
        return () => clearInterval(intervalID);
    }, [showModalMiningTools.show])



    return (
        <>
            <Modal
                open={showModalMiningTools.show}
                onClose={() => setShowModalMiningTools({ ...showModalMiningTools, show: !showModalMiningTools.show })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style} className="text-gray-darker dark:text-gray-light bg-gray-lighter dark:bg-gray-darker outline-none p-3 rounded-xl font-semibold w-full md:w-auto overflow-auto">
                    <div className="relative justify-center items-center">
                        <div className="flex flex-col w-full rounded-lg bg-green mt-2 p-2 space-y-2">
                            <p className="text-left text-lg text-gray-darker">Setup Mining</p>
                            <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker p-2 space-y-2">
                                <div className="grid grid-cols-3 m-0 space-x-0 md:flex md:flex-col md:space-y-3">
                                    <div className="flex flex-col md:flex-row md:justify-center md:items-center md:space-x-0.5 space-y-0.5 order-1 md:order-none" style={{ margin: 0 }}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"0"}
                                                checked={workingTime["0"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    00:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"1"}
                                                checked={workingTime["1"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    01:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"2"}
                                                checked={workingTime["2"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    02:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"3"}
                                                checked={workingTime["3"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    03:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"4"}
                                                checked={workingTime["4"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    04:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"5"}
                                                checked={workingTime["5"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    05:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"6"}
                                                checked={workingTime["6"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    06:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"7"}
                                                checked={workingTime["7"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    07:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />

                                    </div>
                                    <div className="flex flex-col md:flex-row md:justify-center md:items-center md:space-x-0.5 space-y-0.5 order-2 md:order-none" style={{ margin: 0 }}>

                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"8"}
                                                checked={workingTime["8"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    08:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"9"}
                                                checked={workingTime["9"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    09:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"10"}
                                                checked={workingTime["10"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    10:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"11"}
                                                checked={workingTime["11"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    11:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"12"}
                                                checked={workingTime["12"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    12:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"13"}
                                                checked={workingTime["13"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    13:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"14"}
                                                checked={workingTime["14"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    14:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"15"}
                                                checked={workingTime["15"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    15:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row md:justify-center md:items-center md:space-x-0.5 space-y-0.5 order-3 md:order-none" style={{ margin: 0 }}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"16"}
                                                checked={workingTime["16"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    16:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"17"}
                                                checked={workingTime["17"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    17:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"18"}
                                                checked={workingTime["18"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    18:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"19"}
                                                checked={workingTime["19"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    19:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"20"}
                                                checked={workingTime["20"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    20:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"21"}
                                                checked={workingTime["21"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    21:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"22"}
                                                checked={workingTime["22"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    22:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                name={"23"}
                                                checked={workingTime["23"]}
                                                onChange={handleChangeWorkingTime}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                                            />}
                                            label={
                                                <Box component="div" fontSize={12}>
                                                    23:00
                                                </Box>
                                            }
                                            sx={{ "m": 0 }}
                                            labelPlacement="end"
                                        />
                                    </div>
                                </div>

                                <div className=" flex flex-row w-full space-x-2">
                                    <button className="font-bold h-10 outline-none w-full uppercase rounded-lg py-1.5 px-6 text-xs text-white bg-green hover:text-gray-darker" onClick={() => PushTime()}>Save</button>
                                    <button className="font-bold outline-none uppercase w-full rounded-lg h-10 px-6 text-xs text-white bg-green hover:text-gray-darker" onClick={() => PushTime(true)}>Save All Account</button>
                                </div>

                                <div className="text-center font-extrabold text-red-500" style={{ "fontSize": 10 }}>Coordinated Universal Time Zone (UTC) [ {new Date().toUTCString()} ] </div>
                            </div>

                            <div className="flex flex-col w-full rounded-lg border-2 border-green bg-gray-lighter dark:bg-gray-darker p-2 space-y-2">
                                <div className="flex flex-row -mt-2 ">
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="status"
                                            checked={showModalMiningTools.status}
                                            onChange={handleChangeMineBucket}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        />}
                                        label="Mine Bucket"
                                        labelPlacement="end"

                                    />
                                </div>
                                <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0" style={{ marginTop: 3 }}>
                                    <TextField
                                        value={showModalMiningTools.mine_rarity}
                                        onChange={handleChange}
                                        select // tell TextField to render select
                                        label="Rarity Tools"
                                        variant="outlined"
                                        fullWidth={true}
                                        size="small"
                                        name="mine_rarity"
                                    >
                                        <MenuItem key={1} value="Abundant">
                                            Abundant
                                        </MenuItem>
                                        <MenuItem key={2} value="Common">
                                            Common
                                        </MenuItem>
                                        <MenuItem key={3} value="Rare">
                                            Rare
                                        </MenuItem>
                                        <MenuItem key={4} value="Epic">
                                            Epic
                                        </MenuItem>
                                        <MenuItem key={5} value="Legendary">
                                            Legendary
                                        </MenuItem>
                                        <MenuItem key={6} value="Mythical">
                                            Mythical
                                        </MenuItem>
                                    </TextField>
                                    <TextField
                                        label="Setup pool buckets"
                                        type="number"
                                        InputLabelProps={{ shrink: true, }}
                                        variant="outlined"
                                        fullWidth={true}
                                        size="small"
                                        name="mine_bucket"
                                        value={showModalMiningTools.mine_bucket}
                                        onChange={({ target: { value } }) => { if (/^\d+(\.\d{0,4})?$/.test(value)) { handleChange({ target: { name: "mine_bucket", value: value } }); } }}
                                        InputProps={{ inputProps: { min: "0", max: "10", step: "0.05" } }}
                                    />

                                    <TextField
                                        label="Setup time out / Sec."
                                        type="number"
                                        InputLabelProps={{ shrink: true, }}
                                        variant="outlined"
                                        fullWidth={true}
                                        size="small"
                                        name="time_out"
                                        defaultValue={0}
                                        value={showModalMiningTools.time_out}
                                        onChange={handleChange}
                                        InputProps={{ inputProps: { min: "0", max: "1800", step: "60", lang: "en-US" } }}
                                    />

                                </div>
                                <div>
                                    <table className="table-fixed text-center" style={{ fontSize: 12 }}>
                                        <thead >
                                            <tr >
                                                <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Worlds</th>
                                                <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Abundant</th>
                                                <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Common</th>
                                                <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Epic</th>
                                                <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Legendary</th>
                                                {/* <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Mythic</th> */}
                                                <th className="border-r-2 w-2/12 px-1 text-center font-bold ">Mythical</th>
                                                <th className="px-1 text-center font-bold ">Rare</th>
                                            </tr>
                                        </thead>
                                        <tbody  >
                                            {
                                                worldlist.map((key, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="whitespace-nowrap px-1 border-r-2 font-semibold uppercase text-left underline">
                                                                {key.replace(".world", "")}
                                                            </td>
                                                            <td className="whitespace-nowrap px-1 border-r-2 font-semibold ">
                                                                {parseFloat(pools[key][0]["value"]).toFixed(4)}
                                                            </td>
                                                            <td className="whitespace-nowrap px-1 border-r-2 font-semibold ">
                                                                {parseFloat(pools[key][1]["value"]).toFixed(4)}
                                                            </td>
                                                            <td className="whitespace-nowrap px-1 border-r-2 font-semibold ">
                                                                {parseFloat(pools[key][2]["value"]).toFixed(4)}
                                                            </td>
                                                            <td className="whitespace-nowrap px-1 border-r-2 font-semibold ">
                                                                {parseFloat(pools[key][3]["value"]).toFixed(4)}
                                                            </td>
                                                            { <td className="whitespace-nowrap px-1 border-r-2 font-semibold ">
                                                                {parseFloat(pools[key][4]["value"]).toFixed(4)}
                                                            </td> }
                                                            <td className="whitespace-nowrap px-1 border-r-2 font-semibold ">
                                                                {parseFloat(pools[key][5]["value"]).toFixed(4)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className=" flex flex-row w-full space-x-2">
                                    <button className="font-bold h-10 outline-none w-full uppercase rounded-lg py-1.5 px-6 text-xs text-white bg-green hover:text-gray-darker" onClick={() => PushMineBucket()}>Save</button>
                                    <button className="font-bold outline-none uppercase w-full rounded-lg h-10 px-6 text-xs text-white bg-green hover:text-gray-darker" onClick={() => PushMineBucket(true)}>Save All Account</button>
                                </div>

                                <div className="flex flex-row -mt-2 ">
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="statusDelay"
                                            checked={showModalMiningTools.statusDelay}
                                            onChange={handleChangeMineDelay}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        />}
                                        label="Manual Delay Mine"
                                        labelPlacement="end"
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0" style={{ marginTop: 3 }}>
                                    <TextField
                                        label="Setup / Min."
                                        type="number"
                                        InputLabelProps={{ shrink: true, }}
                                        variant="outlined"
                                        fullWidth={true}
                                        size="small"
                                        name="timeDelay"
                                        defaultValue={0}
                                        value={showModalMiningTools.timeDelay}
                                        onChange={handleChange}
                                        InputProps={{ inputProps: { min: "0", max: "1440", step: "30", lang: "en-US" } }}
                                    />
                                    <button className="font-bold outline-none uppercase w-full rounded-lg h-10 px-6 text-xs text-white bg-green hover:text-gray-darker" onClick={() => PushManualDelayMine()}>Save</button>
                                    <button className="font-bold outline-none uppercase w-full rounded-lg h-10 px-6 text-xs text-white bg-green hover:text-gray-darker" onClick={() => PushManualDelayMine(true)}>Save All Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal >
        </>
    );
}

