import React, { useContext } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ModalContext } from "../content/TableProfile";
import { toast } from "react-toastify";
import { dayjs } from "./Date";
const fetch = require('node-fetch');
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #8DC53F',
};

export default function SetTimeModal(props) {

    const { showModalSetTime, setShowModalSetTime } = useContext(ModalContext);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setShowModalSetTime((prevTime) => {
            return {
                ...prevTime,
                [name]: Number(value)
            };
        });
    };

    const PushTime = async () => {

        const options = {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('token'),
                "Content-type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                account: showModalSetTime.account,
                actions: {
                    start: showModalSetTime.start,
                    stop: showModalSetTime.stop,
                },
            })
        };

        const SendPushTransaction = new Promise(async (resolve, rejected) => {
            await fetch("/working", options)
                .then(res => res.json())
                .then(json => resolve(json))
                .catch(err => rejected(err));
        });

        toast.promise(
            SendPushTransaction,
            {
                pending: 'Promise is pending',
                success: {
                    render({ data }) { return `Succeed Setup` },
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return `Failed Setup`
                    }
                }
            }, { theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" }
        )
    }

    return (
        <>
            <Modal
                open={showModalSetTime.show}
                onClose={() => setShowModalSetTime(!showModalSetTime.show)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500, }}
            >
                <Box sx={style} className={"text-gray-darker dark:text-gray-light bg-gray-lighter dark:bg-gray-darker rounded-xl max-w-7xl p-3 space-y-3 flex flex-col "}>
                    <h1>Setup Working</h1>
                    <TextField
                        label="Work Script"
                        type="number"
                        value={showModalSetTime.start}
                        defaultValue={0}
                        size="small"
                        name="start"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true, }}
                        InputProps={{ endAdornment: <InputAdornment position="end">Hour</InputAdornment>, }}
                    />
                    <TextField
                        label="Standby Script"
                        type="number"
                        defaultValue={0}
                        value={showModalSetTime.stop}
                        size="small"
                        name="stop"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true, }}
                        InputProps={{ endAdornment: <InputAdornment position="end">Hour</InputAdornment>, }}
                    />
                    <div className="grid grid-cols-2 gap-4 text-xs">
                        <span className="text-left">Work Time</span>
                        <span className="w-full bg-green rounded-lg text-right text-gray-darkest px-2"> {dayjs.utc(showModalSetTime.strStart).local().format("YYYY-MM-DD HH:mm:ss")}</span>
                        <span className="text-left">Standby Time</span>
                        <span className="w-full bg-warning rounded-lg text-right text-gray-darkest px-2"> {dayjs.utc(showModalSetTime.strStop).local().format("YYYY-MM-DD HH:mm:ss")}</span>
                    </div>

                    <button className="flex w-full items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-6 text-xs leading-normal text-white bg-green hover:text-gray-darker focus:bg-green-dark active:bg-green-dark hover:shadow-inner"
                        onClick={() => PushTime()}
                    >
                        Submit
                    </button>
                </Box>
            </Modal>
        </>

    );
}
export { SetTimeModal };
