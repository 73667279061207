/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useContext } from "react";
//import preval from 'preval.macro';
import { ReBootServer } from "../components/models/Reboot";
import socketIOClient from "socket.io-client";
import TableProfile from "../components/content/TableProfile";
// import Tooltip from '@mui/material/Tooltip';
import { MainProFileContext } from "../App";
const fetch = require("node-fetch");


export default function MainProfile() {
    const { popAddAccount, setPopAddAccount, popMemberShipAW, setPopMemberShipAW, masterKey, setMasterKey, setResponse, totalTlm, totalWax, totalRewards, TLMUSDT, setTLMUSDT, WAXPUSDT, setWAXPUSDT, setLogin, setTimeServer } = useContext(MainProFileContext);
    // const [hostCPU, setHostCPU] = useState(0)
    /*const version = preval`
                            const { execSync } = require('child_process')
                            const rawLog = execSync('git log --no-decorate -n 1 --no-merges', {
                            encoding: 'utf-8',
                            })
                            module.exports = /commit ([a-z0-9]+)/gi.exec(rawLog)[1]
                            `*/
    const Get_WAXPUSDT = async () => {
        await fetch("https://api.binance.com/api/v3/ticker/price?symbol=WAXPUSDT")
            .then(res => res.json())
            .then(json => { setWAXPUSDT(json.price) })
            .catch(err => console.error('error:' + err));
    }

    const Get_TLMUSDT = async () => {
        await fetch("https://api.binance.com/api/v3/ticker/price?symbol=TLMUSDT")
            .then(res => res.json())
            .then(json => { setTLMUSDT(json.price) })
            .catch(err => console.error('error:' + err));
    }

    const [bucketEyeke, setBucketEyeke] = useState({
        "rows": [
            {
                "last_fill_time": "2022-01-10T12:16:18.000",
                "fill_rate": "0",
                "bucket_total": "0.0000 TLM",
                "mine_bucket": "0.0000 TLM"
            }
        ],
    })
    const GetPlanetEyeke = async () => {
        const url = 'https://wax.pink.gg/v1/chain/get_table_rows';

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "json": true,
                "code": "m.federation",
                "scope": "eyeke.world",
                "table": "state3",
                "reverse": false,
                "show_payer": false
            })
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(json => { setBucketEyeke(json) })
            .catch(err => console.error('error:' + err));
    }

    const [bucketKavian, setBucketKavian] = useState({
        "rows": [
            {
                "last_fill_time": "2022-01-10T12:16:18.000",
                "fill_rate": "0",
                "bucket_total": "0.0000 TLM",
                "mine_bucket": "0.0000 TLM"
            }
        ],
    })
    const GetPlanetKavian = async () => {
        const url = 'https://wax.pink.gg/v1/chain/get_table_rows';

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "json": true,
                "code": "m.federation",
                "scope": "kavian.world",
                "table": "state3",
                "reverse": false,
                "show_payer": false
            })
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(json => { setBucketKavian(json) })
            .catch(err => console.error('error:' + err));
    }

    const [bucketMagor, setBucketMagor] = useState({
        "rows": [
            {
                "last_fill_time": "2022-01-10T12:16:18.000",
                "fill_rate": "0",
                "bucket_total": "0.0000 TLM",
                "mine_bucket": "0.0000 TLM"
            }
        ],
    })
    const GetPlanetMagor = async () => {
        const url = 'https://wax.pink.gg/v1/chain/get_table_rows';

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "json": true,
                "code": "m.federation",
                "scope": "magor.world",
                "table": "state3",
                "reverse": false,
                "show_payer": false
            })
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(json => { setBucketMagor(json) })
            .catch(err => console.error('error:' + err));
    }

    const [bucketNaron, setBucketNaron] = useState({
        "rows": [
            {
                "last_fill_time": "2022-01-10T12:16:18.000",
                "fill_rate": "0",
                "bucket_total": "0.0000 TLM",
                "mine_bucket": "0.0000 TLM"
            }
        ],
    })
    const GetPlanetNaron = async () => {
        const url = 'https://wax.pink.gg/v1/chain/get_table_rows';

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "json": true,
                "code": "m.federation",
                "scope": "naron.world",
                "table": "state3",
                "reverse": false,
                "show_payer": false
            })
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(json => { setBucketNaron(json) })
            .catch(err => console.error('error:' + err));
    }

    const [bucketNeri, setBucketNeri] = useState({
        "rows": [
            {
                "last_fill_time": "2022-01-10T12:16:18.000",
                "fill_rate": "0",
                "bucket_total": "0.0000 TLM",
                "mine_bucket": "0.0000 TLM"
            }
        ],
    })
    const GetPlanetNeri = async () => {
        const url = 'https://wax.pink.gg/v1/chain/get_table_rows';

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "json": true,
                "code": "m.federation",
                "scope": "neri.world",
                "table": "state3",
                "reverse": false,
                "show_payer": false
            })
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(json => { setBucketNeri(json) })
            .catch(err => console.error('error:' + err));
    }

    const [bucketVeles, setBucketVeles] = useState({
        "rows": [
            {
                "last_fill_time": "2022-01-10T12:16:18.000",
                "fill_rate": "0",
                "bucket_total": "0.0000 TLM",
                "mine_bucket": "0.0000 TLM"
            }
        ],
    })
    const GetPlanetVeles = async () => {
        const url = 'https://wax.pink.gg/v1/chain/get_table_rows';

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "json": true,
                "code": "m.federation",
                "scope": "veles.world",
                "table": "state3",
                "reverse": false,
                "show_payer": false
            })
        };

        await fetch(url, options)
            .then(res => res.json())
            .then(json => { setBucketVeles(json) })
            .catch(err => console.error('error:' + err));
    }

    const handleClick = (myLink) => () => {
        window.open(myLink)
    }

    useEffect(() => {
        const socket = socketIOClient(window.location.href);
        socket.on("MasterKey", (data) => { setMasterKey(data) });
        socket.on("FromAPI", (data) => { setResponse(data); });
        socket.on("Time_Sever", (data) => { setTimeServer(data); });
        Get_WAXPUSDT()
        Get_TLMUSDT()
        GetPlanetEyeke()
        GetPlanetKavian()
        GetPlanetMagor()
        GetPlanetNaron()
        GetPlanetNeri()
        GetPlanetVeles()
        setInterval(async () => { await Get_WAXPUSDT(); await Get_TLMUSDT(); }, 60000);
        setInterval(async () => { GetPlanetEyeke(); GetPlanetKavian(); GetPlanetMagor(); GetPlanetNaron(); GetPlanetNeri(); GetPlanetVeles(); }, 30000);
    }, []);

    const [currentYear] = useState(() => new Date().getFullYear());

    return (
        <div className="min-h-screen min-w-screen bg-gray-lighter text-black dark:bg-gray-darkest dark:text-white">
            <section className="relative py-3">
                <div className="container mx-auto px-4">
                    <div className="relative flex flex-col min-w-0 break-words  bg-gray-lighter text-black dark:bg-gray-darkest dark:text-white w-full mb-6 shadow-xl rounded-lg mt-4">
                        <div className="p-6 text-right ">
                            <div className="flex flex-wrap justify-center">
                                <div className="w-full flex justify-center">
                                    <div className="relative ">
                                        <img
                                            alt="logo"
                                            src={
                                                require("../assets/img/logo-new.png").default
                                            }
                                            className="h-auto "
                                            style={{ maxWidth: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="flex w-full flex-col justify-items-end sm:flex-row sm:mt-6 sm:space-x-3.5 sm:justify-center sm:items-center">
                                    <div className="flex flex-nowrap items-center space-x-2">
                                        <img
                                            className="h-4 sm:h-10"
                                            src={require("../assets/img/tlm.png").default}
                                            alt="TLM"
                                        />
                                        <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                            <strong className="whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light">{Number.parseFloat(totalTlm).toFixed(4)} TLM</strong>
                                            <span className="-mt-2 whitespace-nowrap flex-grow text-sm sm:text-xs text-gray-darker dark:text-gray-light font-semibold">REWARDS : {Number.parseFloat(totalRewards).toFixed(4)} TLM</span>
                                            <span style={{ fontSize: 8 }} className="invisible md:visible -mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">1 TLM : {Number.parseFloat(TLMUSDT).toFixed(3)} USDT</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <img
                                            className="h-4 sm:h-10"
                                            src={require("../assets/img/wax.png").default}
                                            alt="WAX"
                                        />
                                        <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                            <strong className="whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light">{Number.parseFloat(totalWax).toFixed(8)} WAX</strong>
                                            <span style={{ fontSize: 8 }} className="invisible md:visible -mt-4 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">1 WAX : {Number.parseFloat(WAXPUSDT).toFixed(3)} USDT</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-nowrap items-center space-x-2">
                                        <img
                                            className="h-4 sm:h-10"
                                            src={require("../assets/img/usdt.png").default}
                                            alt="USDT"
                                        />
                                        <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                            <strong className="whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light">{Number((Number.parseFloat(totalTlm) * Number.parseFloat(TLMUSDT)) + (Number.parseFloat(totalWax) * Number.parseFloat(WAXPUSDT))).toFixed(3)} USDT</strong>
                                        </div>

                                    </div>
                                    <div className="flex flex-nowrap items-center space-x-2">
                                        <img
                                            className="h-4 sm:h-10"
                                            src={require("../assets/img/logo.png").default}
                                            alt="USDT"
                                        />
                                        <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                            <strong className="whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light">{Number(masterKey.coin).toFixed(4)} COIN</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-none md:grid-cols-2 items-center align-middle">
                            <div className={"flex justify-center md:justify-start space-x-2"}>
                                <button
                                    className={`text-purple-500 border-2 border-solid rounded-xl border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                                    type="button"
                                    onClick={() => setPopMemberShipAW(!popMemberShipAW)}
                                >
                                    Member Ship
                                </button>
                                <button
                                    className={`text-purple-500 border-2 border-solid rounded-xl border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                                    type="button"
                                    onClick={() => setPopAddAccount(!popAddAccount)}
                                >
                                    + Account
                                </button>
                                {/* <button
                                className={`text-purple-500 border-2 border-solid rounded-xl border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                                type="button"
                                onClick={async () => {await ReBootServer()}}
                            >
                                Reboot Server
                            </button> */}
                            </div>
                            <div className={"flex justify-center md:justify-end space-x-2"}>
                                <button
                                    className={`text-purple-500 border-2 border-solid rounded-xl border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                                    type="button"
                                    onClick={async () => {
                                        await fetch("/start", { method: "GET", headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), }, });
                                    }}
                                >
                                    Start All
                                </button>
                                <button
                                    className={`text-purple-500 border-2 border-solid rounded-xl border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                                    type="button"
                                    onClick={async () => {
                                        await fetch("/stop", { method: "GET", headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), }, });
                                    }}
                                >
                                    Stop All
                                </button>
                                <button
                                    className={`text-purple-500 border-2 border-solid rounded-xl border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150`}
                                    type="button"
                                    onClick={async () => {
                                        await fetch("/delete", { method: "GET", headers: { 'Authorization': "Bearer " + localStorage.getItem('token'), }, });
                                    }}
                                >
                                    Delete All
                                </button>

                            </div>
                        </div>
                        <div className="block items-center justify-center mt-3 w-full">
                            <TableProfile />
                        </div >
                        <div className="md:transform hover:scale-110 md:duration-500">
                            <div className="flex flex-col w-full space-y-2 md:flex-row md:items-center md:justify-center md:space-x-4 md:mt-2">
                                <div className="flex items-center space-x-2" onClick={handleClick("http://www.awstats.io/land/planet/eyeke")}>
                                    <img
                                        className="h-6 sm:h-12"
                                        src={require("../assets/img/planets/eyeke.png").default}
                                        alt="eyeke"
                                    />
                                    <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                        <strong className="whitespace-nowrap flex-grow text-sm sm:text-md text-gray-darker dark:text-white">Eyeke</strong>
                                        <span style={{ fontSize: 8 }} className="-mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Bucket Total : {bucketEyeke["rows"][0]["bucket_total"]}</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold hidden md:block">Fill Rate : {parseFloat(bucketEyeke["rows"][0]["fill_rate"] / 10000).toFixed(4)} TLM/Sec.</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Mine Bucket : {bucketEyeke["rows"][0]["mine_bucket"]}</span>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-2" onClick={handleClick("http://www.awstats.io/land/planet/kavian")}>
                                    <img
                                        className="h-6 sm:h-12"
                                        src={require("../assets/img/planets/kavian.png").default}
                                        alt="kavian"
                                    />
                                    <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                        <strong className="whitespace-nowrap flex-grow text-sm sm:text-md text-gray-darker dark:text-white">Kavian</strong>
                                        <span style={{ fontSize: 8 }} className="-mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Bucket Total : {bucketKavian["rows"][0]["bucket_total"]}</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold hidden md:block">Fill Rate : {parseFloat(bucketKavian["rows"][0]["fill_rate"] / 10000).toFixed(4)} TLM/Sec.</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Mine Bucket : {bucketKavian["rows"][0]["mine_bucket"]}</span>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-2" onClick={handleClick("http://www.awstats.io/land/planet/magor")}>
                                    <img
                                        className="h-6 sm:h-12"
                                        src={require("../assets/img/planets/magor.png").default}
                                        alt="magor"
                                    />
                                    <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                        <strong className="whitespace-nowrap flex-grow text-sm sm:text-md text-gray-darker dark:text-white">Magor</strong>
                                        <span style={{ fontSize: 8 }} className="-mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Bucket Total : {bucketMagor["rows"][0]["bucket_total"]}</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold hidden md:block">Fill Rate : {parseFloat(bucketMagor["rows"][0]["fill_rate"] / 10000).toFixed(4)} TLM/Sec.</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Mine Bucket : {bucketMagor["rows"][0]["mine_bucket"]}</span>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-2" onClick={handleClick("http://www.awstats.io/land/planet/naron")}>
                                    <img
                                        className="h-6 sm:h-12"
                                        src={require("../assets/img/planets/naron.png").default}
                                        alt="naron"
                                    />
                                    <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                        <strong className="whitespace-nowrap flex-grow text-sm sm:text-md text-gray-darker dark:text-white">Naron</strong>
                                        <span style={{ fontSize: 8 }} className="-mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Bucket Total : {bucketNaron["rows"][0]["bucket_total"]}</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold hidden md:block">Fill Rate : {parseFloat(bucketNaron["rows"][0]["fill_rate"] / 10000).toFixed(4)} TLM/Sec.</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Mine Bucket : {bucketNaron["rows"][0]["mine_bucket"]}</span>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-2" onClick={handleClick("http://www.awstats.io/land/planet/neri")}>
                                    <img
                                        className="h-6 sm:h-12"
                                        src={require("../assets/img/planets/neri.png").default}
                                        alt="neri"
                                    />
                                    <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                        <strong className="whitespace-nowrap flex-grow text-sm sm:text-md text-gray-darker dark:text-white">Neri</strong>
                                        <span style={{ fontSize: 8 }} className="-mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Bucket Total : {bucketNeri["rows"][0]["bucket_total"]}</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold hidden md:block">Fill Rate : {parseFloat(bucketNeri["rows"][0]["fill_rate"] / 10000).toFixed(4)} TLM/Sec.</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Mine Bucket : {bucketNeri["rows"][0]["mine_bucket"]}</span>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-2" onClick={handleClick("http://www.awstats.io/land/planet/veles")}>
                                    <img
                                        className="h-6 sm:h-12"
                                        src={require("../assets/img/planets/veles.png").default}
                                        alt="veles"
                                    />
                                    <div className="sm:flex sm:w-full sm:flex-col sm:justify-start space-x-2 md:space-x-0">
                                        <strong className="whitespace-nowrap flex-grow text-sm sm:text-md text-gray-darker dark:text-white">Veles</strong>
                                        <span style={{ fontSize: 8 }} className="-mt-3 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Bucket Total : {bucketVeles["rows"][0]["bucket_total"]}</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold hidden md:block">Fill Rate : {parseFloat(bucketVeles["rows"][0]["fill_rate"] / 10000).toFixed(4)} TLM/Sec.</span>
                                        <span style={{ fontSize: 8 }} className="-mt-5 whitespace-nowrap flex-grow text-sm sm:text-xl text-gray-darker dark:text-gray-light font-semibold">Mine Bucket : {bucketVeles["rows"][0]["mine_bucket"]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center mt-2">
                            <div className="flex flex-col">
                                <div className="text-sm text-center text-gray-lighter font-semibold py-1 uppercase">
                                    <a
                                        href="https://www.facebook.com/alienscriptbot"
                                        className=" text-gray-darkest dark:text-white"
                                    >
                                        Copyright © {currentYear} alien-script.com <br /> ALL RIGHTS RESERVED
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}